import { v4 as uuidv4 } from 'uuid';

import { config } from './bi-api';
import { biConstants } from './bi-constants';
import { TSdkState } from './bi-types';
import { biUtils } from './bi-utils';

/**
 * @description Initialize BlitzBi sdk with the app_id and app_token.
 * */
export const initializeAppIdAndAppToken = () => {
  let sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
  sdkState = {
    ...sdkState,
    blitzAppId: config.blitzAppId.toString(),
    appToken: config.blitzAppToken,
    baseUrl: config.baseUrl,
    installAppVersion: biConstants.APP_VERSION,
    deviceType: 'WEB',
  };
  localStorage.setItem(
    biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE,
    JSON.stringify(sdkState),
  );
};

/**
 * @description Initialize blitzDeviceId.
 * */
export const initializeBlitzDeviceId = () => {
  let sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
  const blitzDeviceId: string = uuidv4();
  sdkState = { ...sdkState, blitzDeviceId: blitzDeviceId };
  localStorage.setItem(
    biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE,
    JSON.stringify(sdkState),
  );
};

/**
 * @description Initialize JRX deviceId.
 * */
export const initializeJrxDeviceId = (id: string | null) => {
  let sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
  sdkState = { ...sdkState, deviceId: id };
  localStorage.setItem(
    biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE,
    JSON.stringify(sdkState),
  );
};

/**
 * @description Initialize adId.
 * */
export const initializeAdId = (adId: string) => {
  let sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
  sdkState = { ...sdkState, adId: adId };
  localStorage.setItem(
    biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE,
    JSON.stringify(sdkState),
  );
};

/**
 * @description Initialize blitzUserId.
 * */
export const initializeBlitzUserId = (blitzUserId: string) => {
  let sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
  sdkState = { ...sdkState, blitzUserId: blitzUserId };
  localStorage.setItem(
    biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE,
    JSON.stringify(sdkState),
  );
};

/**
 * @description Use this to update appSpecificDeviceId.
 * */
export const initializeAppSpecificDeviceId = (appSpecificDeviceId: string) => {
  let sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
  sdkState = { ...sdkState, appSpecificDeviceId: appSpecificDeviceId };
  localStorage.setItem(
    biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE,
    JSON.stringify(sdkState),
  );
};

/**
 * @description Use this to set idForAdvertiser.
 * */
export const initializeIFA = (ifa: string) => {
  let sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
  sdkState = { ...sdkState, ifa: ifa };
  localStorage.setItem(
    biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE,
    JSON.stringify(sdkState),
  );
};

/**
 * @description Use this to set idForVendor.
 * */
export const initializeIFV = (ifv: string) => {
  let sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
  sdkState = { ...sdkState, ifv: ifv };
  localStorage.setItem(
    biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE,
    JSON.stringify(sdkState),
  );
};

/**
 * @description Use this to set utmContent, utmCampaign, utmMedium or utmMedium
 * this will not overwrite if an existing value is set
 * */
export const initializeUtmParam = (
  key: 'utmSource' | 'utmCampaign' | 'utmMedium' | 'utmContent',
  value: string | null,
) => {
  let sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
  sdkState = { ...sdkState, [key]: sdkState[key] || value };
  localStorage.setItem(
    biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE,
    JSON.stringify(sdkState),
  );
};
