import axios from 'axios';
import axiosRetry from 'axios-retry';

import { JR_ENV } from '@/common/constants/env';
import { localStorage } from '@/common/utils';

import {
  TBlitzAdidUpdateRequest,
  TBlitzDeviceIdUpdateRequest,
  TBlitzDeviceUpdateRequest,
  TBlitzUserUpdateRequest,
} from './bi-types';

export const config =
  JR_ENV === 'production'
    ? {
        baseUrl: 'https://prod-blitzbi-infra.superchamps.com:9443',
        blitzAppId: 52,
        blitzAppToken: 'b2bbe772-0f74-418c-a4c2-23e9f95f9708',
      }
    : {
        baseUrl: 'https://blitzbi-test.useblitz.com',
        blitzAppId: 138,
        blitzAppToken: 'fea998e7-0849-4b6b-aa3f-0d19e686b2c0',
      };

const instance = axios.create({
  baseURL: `${config.baseUrl}/app/${config.blitzAppId}`,
  headers: {
    blitzAppId: config.blitzAppId.toString(),
    blitzAppToken: config.blitzAppToken,
    'Content-Type': 'application/json',
  },
});

axiosRetry(instance, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 1000,
});

export async function sendBIEvent(
  payload: Record<string, any>,
  logStr: string,
) {
  try {
    const { data } = await instance.post('/event', payload);

    console.log(`BI log success : ( event => ${logStr} )`);

    return data;
  } catch (e) {
    console.error(`BI Log request failed for: ${logStr} \n`, e);

    if (
      axios.isAxiosError(e) &&
      ['Request aborted', 'Network Error'].includes(e.message)
    ) {
      console.warn('BI Log request failed due to network error');
      return;
    }

    throw e;
  }
}

export async function blitzUpdateDeviceId(
  payload: TBlitzDeviceIdUpdateRequest,
) {
  try {
    await instance.post('/device/updateDeviceId', payload);
    console.log('blitz deviceId updated successfully');
  } catch (e) {
    console.error('blitz deviceId update failed', e);
  }
}

export async function blitzUtmDetailsUpdate(
  fromSource: string,
  deviceId: string,
  utmSource: string,
  utmCampaign: string,
  utmMedium: string,
  utmContent: string,
) {
  try {
    const prevDetails = JSON.parse(
      localStorage.getItem('attributionCallbackPreviousParamsSet') || '{}',
    );
    if (
      (!prevDetails['deviceIdP'] && deviceId) ||
      (!prevDetails['utmSourceP'] && utmSource) ||
      (!prevDetails['utmCampaignP'] && utmCampaign) ||
      (!prevDetails['utmMediumP'] && utmMedium) ||
      (!prevDetails['utmContentP'] && utmContent) ||
      prevDetails['deviceIdP'] !== deviceId ||
      (prevDetails['utmSourceP'] !== utmSource &&
        prevDetails['utmSourceP'] === 'organic') ||
      prevDetails['utmCampaignP'] !== utmContent ||
      prevDetails['utmMediumP'] !== utmMedium ||
      prevDetails['utmContentP'] !== utmContent
    ) {
      await instance
        .get(
          '/adjust/app/attributionCallback?fromSource=' +
            fromSource +
            '&blitzAppSpecificIdentifier=' +
            deviceId +
            '&utmSource=' +
            utmSource +
            '&utmCampaign=' +
            utmCampaign +
            '&utmMedium=' +
            utmMedium +
            '&utmContent=' +
            utmContent +
            '&blitzAppId=' +
            config.blitzAppId +
            '&blitzAppToken=' +
            config.blitzAppToken,
        )
        .then((response) => {
          localStorage.setItem(
            'attributionCallbackPreviousParamsSet',
            JSON.stringify({
              deviceIdP: deviceId,
              utmSourceP: utmSource,
              utmCampaignP: utmCampaign,
              utmMediumP: utmMedium,
              utmContentP: utmContent,
            }),
          );
        });
      console.log('blitzUtmDetailsUpdate updated successfully');
    }
  } catch (e) {
    console.error('blitz deviceId update failed', e);
  }
}

/** need to be called on signup
 * use retry mechanism if call fail
 */
export async function blitzUpdateUser(payload: TBlitzUserUpdateRequest) {
  try {
    await instance.post('/user/update', payload);
    console.log('blitz user updated successfully');
  } catch (e) {
    console.error('blitz user update failed', e);
  }
}

/** as soon as user visits for the first time */
export async function blitzUpdateDevice(payload: TBlitzDeviceUpdateRequest) {
  try {
    await instance.post('/device/update', payload);
  } catch (e) {
    console.error('blitz device update failed', e);
  }
}

export async function blitzUpdateAdid(payload: TBlitzAdidUpdateRequest) {
  try {
    await instance.post('/device/updateAdId', payload);
    console.log('blitz AdId updated successfully');
  } catch (e) {
    console.error('blitz AdId update failed', e);
  }
}
