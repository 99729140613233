import { sendBIEvent } from '../bi-api';
import { biFields, EBoolean } from '../bi-constants';
import { TBiEventBeforeLogin } from '../bi-types';
import { biUtils } from '../bi-utils';

import { defaultBiEvents } from './common-bi-events';

export const loginBiEvents = {
  /** row 8 */
  countryPicker: (countryCode: string) => {
    const biEvent: TBiEventBeforeLogin = {
      ...defaultBiEvents(),
      event_name: biFields.eventNames.WEB_ONBOARDING,
      str_field0: biFields.strField0Types.CLICK,
      str_field1: biFields.strField1Types.COUNTRY_PICKER,
      str_field2: biFields.strField2Types.PHONE_NUMBER_EMAIL_SCREEN,
      str_field3: biFields.strField3Types.WEB,
      str_field4: !biUtils.getDeviceDetailsFromLocalStorage()
        ? biFields.strField4Types.NEW
        : biFields.strField4Types.RETURNING,
      str_field6: countryCode === undefined ? 'US' : countryCode,
    };

    sendBIEvent(biEvent, 'user selects a country from country picker, row 8');
  },

  /** row 10 */
  getOtp: (countryCode: string, phoneNumberAdded: boolean, twilio: boolean) => {
    const biEvent: TBiEventBeforeLogin = {
      ...defaultBiEvents(),
      event_name: biFields.eventNames.WEB_ONBOARDING,
      str_field0: biFields.strField0Types.CLICK,
      str_field1: biFields.strField1Types.GET_OTP,
      str_field2: biFields.strField2Types.PHONE_NUMBER_SCREEN,
      str_field3: biFields.strField3Types.WEB,
      str_field4: !biUtils.getDeviceDetailsFromLocalStorage()
        ? biFields.strField4Types.NEW
        : biFields.strField4Types.RETURNING,
      str_field6: countryCode === undefined ? 'US' : countryCode,
      str_field7: phoneNumberAdded ? EBoolean.TRUE : EBoolean.FALSE, // TODO phone number added ?
      str_field8: twilio
        ? biFields.strField8Types.TWILIO
        : biFields.strField8Types.FIREBASE, // TODO firebase/twilio ?
    };

    sendBIEvent(biEvent, 'user clicks on get otp, row 10');
  },

  /** row 7 */
  openPhoneNumberScreen: (countryCode: string) => {
    const biEvent: TBiEventBeforeLogin = {
      ...defaultBiEvents(),
      event_name: biFields.eventNames.WEB_ONBOARDING,
      str_field0: biFields.strField0Types.VIEW_OPEN,
      str_field1: biFields.strField1Types.PHONE_NUMBER_SCREEN,
      str_field3: biFields.strField3Types.WEB,
      str_field4: !biUtils.getDeviceDetailsFromLocalStorage()
        ? biFields.strField4Types.NEW
        : biFields.strField4Types.RETURNING,
      str_field6: countryCode === undefined ? 'US' : countryCode,
    };

    sendBIEvent(biEvent, 'user opens phone number screen, row 7');
  },

  /** row 9 */
  userEntersInvalidPhNumber: (reasonForInvalidPhNumber: string) => {
    const biEvent: TBiEventBeforeLogin = {
      ...defaultBiEvents(),
      event_name: biFields.eventNames.WEB_ONBOARDING,
      str_field0: biFields.strField0Types.STATE_CHANGE,
      str_field1: biFields.strField1Types.INVALID_NUMBER,
      str_field2: biFields.strField2Types.PHONE_NUMBER_SCREEN,
      str_field3: biFields.strField3Types.WEB,
      str_field4: !biUtils.getDeviceDetailsFromLocalStorage()
        ? biFields.strField4Types.NEW
        : biFields.strField4Types.RETURNING,
      str_field8: reasonForInvalidPhNumber,
    };

    sendBIEvent(biEvent, 'user enters an invalid phone number, row 9');
  },

  /** row 11 */
  phNoInvalidMesgReceivedFromTwilio: (
    countryCode: string,
    reasonForInvalidPhNumber: string,
    errorMesgShownToUser: string,
  ) => {
    const biEvent: TBiEventBeforeLogin = {
      ...defaultBiEvents(),
      event_name: biFields.eventNames.WEB_ONBOARDING,
      str_field0: biFields.strField0Types.STATE_CHANGE,
      str_field1: biFields.strField1Types.ERROR,
      str_field2: biFields.strField2Types.PHONE_NUMBER_SCREEN,
      str_field3: biFields.strField3Types.WEB,
      str_field4: !biUtils.getDeviceDetailsFromLocalStorage()
        ? biFields.strField4Types.NEW
        : biFields.strField4Types.RETURNING,
      str_field6: countryCode === undefined ? 'US' : countryCode,
      str_field7: EBoolean.TRUE,
      str_field8: reasonForInvalidPhNumber,
      str_field9: errorMesgShownToUser,
    };

    sendBIEvent(
      biEvent,
      'phone number invalid message received from twilio/firebase, row 11',
    );
  },

  /** row 15 */
  userEntersIncorrectOtp: (isEmailOtp: boolean, otp: string) => {
    const biEvent: TBiEventBeforeLogin = {
      ...defaultBiEvents(),
      event_name: biFields.eventNames.WEB_ONBOARDING,
      str_field0: biFields.strField0Types.STATE_CHANGE,
      str_field1: biFields.strField1Types.INCORRECT_OTP_ENTERED,
      str_field2: isEmailOtp
        ? biFields.strField2Types.EMAIL_OTP_SCREEN
        : biFields.strField2Types.PHONE_NUMBER_OTP_SCREEN,
      str_field3: biFields.strField3Types.WEB,
      str_field4: !biUtils.getDeviceDetailsFromLocalStorage()
        ? biFields.strField4Types.NEW
        : biFields.strField4Types.RETURNING,
      str_field6: otp,
    };

    sendBIEvent(
      biEvent,
      'user enters incorrect otp for either phone or email, row 15',
    );
  },

  /** row 16 */
  otpIsNotSent: (
    isEmailOtp: boolean,
    reasonForInvalidPhNumber: string,
    errorMesgShownToUser: string,
  ) => {
    const biEvent: TBiEventBeforeLogin = {
      ...defaultBiEvents(),
      event_name: biFields.eventNames.WEB_ONBOARDING,
      str_field0: biFields.strField0Types.STATE_CHANGE,
      str_field1: biFields.strField1Types.ERROR,
      str_field2: isEmailOtp
        ? biFields.strField2Types.EMAIL_OTP_SCREEN
        : biFields.strField2Types.PHONE_NUMBER_OTP_SCREEN,
      str_field3: biFields.strField3Types.WEB,
      str_field4: !biUtils.getDeviceDetailsFromLocalStorage()
        ? biFields.strField4Types.NEW
        : biFields.strField4Types.RETURNING,
      str_field8: reasonForInvalidPhNumber,
      str_field9: errorMesgShownToUser,
    };

    sendBIEvent(
      biEvent,
      'when otp is not sent and user sees an error for either phone number or email, row 16',
    );
  },

  view: (options: any) => {
    const biEvent: TBiEventBeforeLogin = {
      ...defaultBiEvents(),
      event_name: biFields.eventNames.WEB_ONBOARDING,
      str_field0: biFields.strField0Types.VIEW_OPEN,
      ...options,
    };

    sendBIEvent(biEvent, 'Onboarding flow - view_open');
  },
  click: (options: any) => {
    const biEvent: TBiEventBeforeLogin = {
      ...defaultBiEvents(),
      event_name: biFields.eventNames.WEB_ONBOARDING,
      str_field0: biFields.strField0Types.CLICK,
      ...options,
    };

    sendBIEvent(biEvent, 'Onboarding flow - click');
  },
  stateChange: (options: any) => {
    const biEvent: TBiEventBeforeLogin = {
      ...defaultBiEvents(),
      event_name: biFields.eventNames.WEB_ONBOARDING,
      str_field0: biFields.strField0Types.STATE_CHANGE,
      ...options,
    };

    sendBIEvent(biEvent, 'Onboarding flow - state_change');
  },
};
