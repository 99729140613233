import {
  blitzUpdateAdid,
  blitzUpdateDevice,
  blitzUpdateDeviceId,
  blitzUpdateUser,
} from '../bi-api';
import {
  TBlitzAdidUpdateRequest,
  TBlitzDeviceIdUpdateRequest,
  TBlitzDeviceUpdateRequest,
  TBlitzUserUpdateRequest,
  TSdkState,
} from '../bi-types';
import { biUtils } from '../bi-utils';

export const sdkBiEvents = {
  // as soon as we have the DEVICE_DETAILS in localStorage
  blitzDeviceIdUpdate: () => {
    const sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
    const biEvent: TBlitzDeviceIdUpdateRequest = {
      deviceId: sdkState.deviceId,
      ifv: sdkState.ifv,
      deviceType: sdkState.deviceType,
      appSpecificDeviceId: sdkState.appSpecificDeviceId + '',
      ifa: sdkState.ifa,
      installAppVersion: sdkState.installAppVersion,
      blitzAppId: sdkState.blitzAppId,
      blitzDeviceId: sdkState.blitzDeviceId,
    };
    blitzUpdateDeviceId(biEvent);
  },

  // as soon as we have the DEFAULT_BI_EVENT in localStorage
  blitzUserUpdate: () => {
    const sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
    const biEvent: TBlitzUserUpdateRequest = {
      ifv: sdkState.ifv,
      deviceType: sdkState.deviceType,
      ifa: sdkState.ifa,
      installAppVersion: sdkState.installAppVersion,
      blitzAppId: sdkState.blitzAppId,
      blitzDeviceId: sdkState.blitzDeviceId,
      blitzUserId: sdkState.blitzUserId,
      utmCampaign: sdkState.utmCampaign,
      utmContent: sdkState.utmContent,
      utmMedium: sdkState.utmMedium,
      utmSource: sdkState.utmSource,
    };
    blitzUpdateUser(biEvent);
  },

  // as soon as we have the DEVICE_DETAILS in localStorage
  blitzDeviceUpdate: () => {
    const sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
    const biEvent: TBlitzDeviceUpdateRequest = {
      ifa: sdkState.ifa,
      ifv: sdkState.ifv,
      deviceType: sdkState.deviceType,
      installAppVersion: sdkState.installAppVersion,
      blitzAppId: sdkState.blitzAppId,
      blitzDeviceId: sdkState.blitzDeviceId,
      appSpecificDeviceId: sdkState.appSpecificDeviceId,
      adid: sdkState.adId,
      utmCampaign: sdkState.utmCampaign,
      utmContent: sdkState.utmContent,
      utmMedium: sdkState.utmMedium,
      utmSource: sdkState.utmSource,
    };
    blitzUpdateDevice(biEvent);
  },

  blitzAdidUpdate: () => {
    const sdkState: TSdkState = biUtils.getSdkStateFromLocalStorage();
    const biEvent: TBlitzAdidUpdateRequest = {
      deviceType: sdkState.deviceType,
      appSpecificDeviceId: sdkState.appSpecificDeviceId,
      installAppVersion: sdkState.installAppVersion,
      blitzAppId: sdkState.blitzAppId,
      blitzDeviceId: sdkState.blitzDeviceId,
      adid: sdkState.adId,
    };
    blitzUpdateAdid(biEvent);
  },
};
