const traitPercentages = {
  mood: {
    'A Golden Mood': 0.03,
    Chromeborg: 0.03,
    'Gold Mastiff': 0.03,
    'Gold Medalist ♂': 0.03,
    'Golden Machine': 0.03,
    'Lemon Linard': 0.03,
    Playground: 0.03,
    'Queen3.0': 0.03,
    'Reality Bite': 0.03,
    'Steel Kitty': 0.03,
    'All my Rage': 0.06,
    'Gilded Magpie': 0.06,
    'Gold Mechasaurus': 0.06,
    'Golden Muncher': 0.06,
    Ironwolf: 0.06,
    'Knight Cold': 0.06,
    'Metal Foxy': 0.06,
    'Min Sin': 0.06,
    'Queen3.1': 0.06,
    'Steel Shark': 0.06,
    '60$ Paint': 0.09,
    'Death Metal': 0.09,
    'Gilded Monkey': 0.09,
    'Gold Medalist ♀': 0.09,
    'Golden Master': 0.09,
    Mechasaurus: 0.09,
    'Metal Monster': 0.09,
    'Peach Fire': 0.09,
    'Pink Diamond': 0.09,
    'Power Word': 0.09,
    'Red Head': 0.09,
    'Red Rum': 0.09,
    Sarah: 0.09,
    'Till Death': 0.09,
    'Unknown Fear': 0.09,
    'Below Mint': 0.12,
    'Blood Moon': 0.12,
    'Cat Scratch': 0.12,
    'Destiny +4': 0.12,
    'Ghost Mage': 0.12,
    'Gilded Monster': 0.12,
    'Grey Ser': 0.12,
    "I'm Blue": 0.12,
    'Iron Fist': 0.12,
    'Just a rat': 0.12,
    'Sand Cone': 0.12,
    'A Metal Hour': 0.15,
    'Blue Bombshell': 0.15,
    'Blue Windigo': 0.15,
    'Evil Queen': 0.15,
    'Golden Mauser': 0.15,
    'Goth Tennis': 0.15,
    Grim: 0.15,
    'Holiday Shopper': 0.15,
    'No Time to Not': 0.15,
    'Rew Tile': 0.15,
    'Silver Child': 0.15,
    'Three feet outside': 0.15,
    'Vampire X': 0.15,
    'A Lost Lamb': 0.18,
    Alice: 0.18,
    'Die Never Once': 0.18,
    'Grave God': 0.18,
    'Green Goose': 0.18,
    'Lime Crime': 0.18,
    Phill: 0.18,
    Poser: 0.18,
    'Pretty In Pink': 0.18,
    'Shark Clothing': 0.18,
    'Steel Drum': 0.18,
    'The Last Chance': 0.18,
    'X Zero Meta One': 0.18,
    'A Caught Criminal': 0.21,
    'A Typo': 0.21,
    'Blonde Cap': 0.21,
    Coyote: 0.21,
    Everyday: 0.21,
    'Evil Kitty': 0.21,
    'Forever 12': 0.21,
    'Mr. Jay': 0.21,
    Perfect: 0.21,
    'Sigma Sir': 0.21,
    Silverfinger: 0.21,
    Working: 0.21,
    'A Favorite Dress': 0.24,
    'Angry Tweeter': 0.24,
    'Candy Lost': 0.24,
    'Jacket $9.95': 0.24,
    'Nice Job': 0.24,
    'Night Gran': 0.24,
    Outsider: 0.24,
    'Quiet Night': 0.24,
    'Red Flower': 0.24,
    'Report on Monday': 0.24,
    'Shabby God': 0.24,
    'Three Moon Wolf': 0.24,
    'What the Fox Says': 0.24,
    'A Finished Promise': 0.27,
    'A Small Truth': 0.27,
    Angel: 0.27,
    Dolly: 0.27,
    'Grove Purple': 0.27,
    'Half-off Goth': 0.27,
    'Night Beat': 0.27,
    'Punk Finder': 0.27,
    'Red Rash': 0.27,
    'Red Row': 0.27,
    'Slippy Ghost': 0.27,
    'Work Work Work': 0.27,
    'Bad Cat': 0.3,
    'Blue Typhoon': 0.3,
    'Crash Mask': 0.3,
    "I'm with the Band": 0.3,
    'In a cage': 0.3,
    'Lost Soul': 0.3,
    'Point Break': 0.3,
    'Red Runner': 0.3,
    'Slip Knot': 0.3,
    Trailblazer: 0.3,
    Wonderer: 0.3,
    'Barn Door': 0.33,
    'Blood Sports': 0.33,
    'Blue Gord': 0.33,
    'Goth Victor': 0.33,
    'I Felt Green': 0.33,
    'Kobo Wins': 0.33,
    'Mauve Mauler': 0.33,
    'Night Fight': 0.33,
    'Pink 360': 0.33,
    Salami: 0.33,
    'Sea Bites': 0.33,
    'Silk Sword': 0.33,
    'The Last Tree': 0.33,
    'Wen Mint?': 0.33,
    'A Cloudy Day': 0.36,
    'A Typical Tuesday': 0.36,
    'Beach Night': 0.36,
    Fashion: 0.36,
    Grundy: 0.36,
    "I don't Think so": 0.36,
    'Jump Start': 0.36,
    'Mint Goddess': 0.36,
    'Q Issued': 0.36,
    'Ruby Roundhouse': 0.36,
    Windigo: 0.36,
    'Working Dino': 0.36,
    JawZ: 0.39,
    Kitsune: 0.39,
    'Pink Fire': 0.39,
    'Radio DJ': 0.39,
    'Rains Every Day': 0.39,
    'Sugar with Coffee': 0.39,
    'Iron Chairs': 0.42,
    'Lost at night': 0.42,
    'Pink Gord': 0.42,
    'Red Nights': 0.42,
    'Rose Iron': 0.42,
    'Beware of Bruja': 0.45,
    'Expected to Die': 0.45,
    'Ran Fire': 0.45,
    'Shark Kigurumi': 0.45,
    'Wild Stine': 0.45,
    'Flesh Horror': 0.48,
    'Left Out': 0.48,
    'Lemon Sauce': 0.48,
    "Saint's Ghost": 0.48,
    Swindle: 0.48,
    "A King's Fear": 0.51,
    'Doctor Maybe?': 0.51,
    'Hot Thesis': 0.51,
    'Red Wine': 0.51,
    'Reptile Lives': 0.51,
    'A Silly Song': 0.54,
    'Green Days': 0.54,
    Mandy: 0.54,
    'Not Stirred Up': 0.54,
    'Out of Style': 0.54,
    'Past or Future': 0.54,
    'Pink Fin': 0.54,
    'Polar Express': 0.54,
    'An Open Elevator': 0.57,
    Charlatan: 0.57,
    Tomorrow: 0.57,
    Trialed: 0.57,
    "You're Not My Dad": 0.57,
    'Best Personality': 0.6,
    Employed: 0.6,
    'Game Day': 0.6,
    'Happy Day': 0.6,
    Lily: 0.6,
    'New Shoes': 0.6,
    'Grease Fire': 0.63,
    'Indigo Ashlock': 0.63,
    'Marry Jane': 0.63,
    'Mint Seeker': 0.63,
    "Mom's Goodbye": 0.63,
    'Name Sinbot': 0.63,
    Saturday: 0.63,
    'The Sanguine Lands': 0.63,
    Witch: 0.63,
    Wolfy: 0.63,
    'Fiery Mint': 0.66,
    'Lemon Rose': 0.66,
    'Masked Yip': 0.66,
    Plug: 0.66,
    Rival: 0.66,
    'An Ocean View': 0.69,
    'Dino Kigurumi': 0.69,
    'Licensed Killer': 0.69,
    'Spectral Fiend': 0.69,
    'Athlete ♂': 0.72,
    'Bonded Killer': 0.72,
    'The Chosen': 0.72,
    'The Spy': 0.72,
    Windy: 0.72,
    Champion: 0.75,
    'Sand Jaws': 0.75,
    'Wolf Person': 0.75,
    'A White Wedding': 0.78,
    'Nine to Five': 0.78,
    'Slightly Shaken': 0.81,
    'Athlete ♀': 0.84,
    'Goth Flower': 0.84,
    'From Russia': 0.87,
    'Night Shade': 0.87,
    'Frankenstein A': 0.9,
    'Pink Borg': 0.9,
    'Night Ride': 0.93,
    'A Red Door': 0.96,
    'Frankenstein B': 0.99,
    'A Good Time': 1.05,
    'Fear Me': 1.05,
    'Hippie B': 1.05,
    Cyborg: 1.08,
    'Martini Time': 1.08,
    'Night School': 1.08,
    Professional: 1.08,
    'Swag Sand': 1.08,
    'Fire Wins': 1.11,
    'Hacker Five': 1.14,
    Agent: 1.17,
    'Fashion Baby': 1.17,
    'Lord Chester': 1.17,
    'Hippie A': 1.23,
    '45 Flower Power': 1.38,
    'Ken Doll': 1.44,
    Karateka: 1.56,
  },
  racket: {
    'Neon Laser': 0.8,
    'Sakura Wand': 1.1,
    Gothic: 2.2,
    'Foam Finger': 2.5,
    'Stop Sign': 3.3,
    'Fly Swatter': 3.4,
    'Frying Pan': 5.2,
    Shovel: 6.0,
    Branch: 6.6,
    'Baseball Bat': 6.9,
    Axe: 7.1,
    Broom: 7.6,
    Modern: 10.1,
    'Ping Pong': 10.6,
    Standard: 13.1,
    Retro: 13.3,
  },
  bling: {
    'Cyber Visor': 0.7,
    'Donut Glasses': 1.7,
    'Doctor Mask': 1.9,
    'Oni Mask': 3.5,
    'Third Eye': 3.9,
    'Paper Talisman': 4.0,
    'Cat Eye Glasses': 6.2,
    'Domino Mask': 6.4,
    Monocle: 6.4,
    Bandaid: 6.8,
    Mask: 6.9,
    Mustache: 7.9,
    'Rasta Glasses': 9.0,
    'Hippie Glasses': 9.1,
    None: 12.9,
    'Agent Glasses': 13.0,
  },
  feels: {
    '._.': 0.5,
    noice: 0.9,
    ded: 1.5,
    TwT: 3.1,
    dizzy: 3.2,
    angry: 3.4,
    ohno: 5.4,
    uhh: 6.3,
    eh: 6.5,
    hah: 6.8,
    relatable: 7.0,
    uhoh: 7.4,
    oh: 10.1,
    yeah: 10.1,
    neutral: 13.4,
    love: 14.4,
  },
  character: {
    Angel: 1.7,
    'Evil Kitty': 2.6,
    Kitsune: 2.8,
    'Dino Kigurumi': 5.3,
    Witch: 5.6,
    'Shark Kigurumi': 5.7,
    'Athlete ♀': 5.9,
    'Athlete ♂': 5.9,
    Wolfy: 5.9,
    Cyborg: 6.3,
    'Frankenstein B': 7.0,
    'Frankenstein A': 7.8,
    Karateka: 9.1,
    'Hippie B': 9.1,
    Agent: 9.3,
    'Hippie A': 9.9,
  },
  moves: {
    "Ball'n": 0.8,
    Dab: 1.7,
    'Front Flip': 1.7,
    'Moon Walk': 3.9,
    Twirl: 3.9,
    'Thumbs Up': 3.9,
    'Run and Jump': 6.1,
    Slide: 6.1,
    Victory: 6.3,
    'I Lost...': 6.5,
    'Epic Dive': 6.9,
    Slam: 7.7,
    'Hip Hoppin': 8.8,
    Backhand: 9.2,
    Windup: 13.1,
    Posing: 13.6,
  },
};

export default traitPercentages;
