import React, { useEffect, useCallback, useMemo, useState } from 'react';

import useLocation from '@/hooks/use-location';

import ApiUser from '../api/user';
import { blitzUtmDetailsUpdate } from '../bi/bi-api';
import { biConstants, INITIAL_SDK_STATE } from '../bi/bi-constants';
import { ifa, ifv, sdkStateFromLs, userAgentStr } from '../bi/bi-utils';
import {
  initializeAdId,
  initializeAppIdAndAppToken,
  initializeAppSpecificDeviceId,
  initializeBlitzDeviceId,
  initializeIFA,
  initializeIFV,
  initializeJrxDeviceId,
  initializeUtmParam,
} from '../bi/blitz-bi';
import { commonBiEvents } from '../bi/events/common-bi-events';
import { sdkBiEvents } from '../bi/events/sdk-bi-events';
import { isBrowser } from '../common/utils';

const isBrowse = isBrowser();

function InitBi({ location = isBrowse ? window.location : '' }) {
  const { pathname } = useLocation();
  const deviceDetailsFromLStorage = isBrowse
    ? localStorage.getItem(biConstants.BI_LOCAL_STORAGE_KEYS.DEVICE_DETAILS)
    : null;
  const referralIdFromLStorage = isBrowse
    ? localStorage.getItem('REFERRAL_ID')
    : null;
  const utmDetailsFromLStorage = isBrowse
    ? localStorage.getItem(biConstants.BI_LOCAL_STORAGE_KEYS.UTM_DETAILS)
    : null;
  const [deviceId, setDeviceId] = useState(
    deviceDetailsFromLStorage && deviceDetailsFromLStorage !== 'null'
      ? JSON.parse(deviceDetailsFromLStorage).device.id
      : null,
  );
  const [referralId, setReferralId] = useState<string>(
    referralIdFromLStorage && referralIdFromLStorage !== 'null'
      ? referralIdFromLStorage
      : null,
  );
  const [utmDetails, setUtmDetails] = useState<string>(
    utmDetailsFromLStorage && utmDetailsFromLStorage !== 'null'
      ? utmDetailsFromLStorage
      : null,
  );
  const latestJrxDeviceId = useMemo(() => {
    return deviceDetailsFromLStorage && deviceDetailsFromLStorage !== 'null'
      ? JSON.parse(deviceDetailsFromLStorage).device.id
      : null;
  }, [deviceDetailsFromLStorage]);

  const searchParms = new URLSearchParams(
    isBrowse ? window.location.search : '',
  );
  const lowerCaseParams = new URLSearchParams();
  for (const [name, value] of searchParms) {
    lowerCaseParams.append(name.toLowerCase(), value);
  }
  const utmContent =
    lowerCaseParams.get('utm_content') ||
    lowerCaseParams.get('utm-content') ||
    lowerCaseParams.get('utmcontent');

  const utmSource =
    lowerCaseParams.get('utm_source') ||
    lowerCaseParams.get('utm-source') ||
    lowerCaseParams.get('utmsource');
  const utmCampaign =
    lowerCaseParams.get('utm_campaign') ||
    lowerCaseParams.get('utm-campaign') ||
    lowerCaseParams.get('utmcampaign');
  const utmMedium =
    lowerCaseParams.get('utm_medium') ||
    lowerCaseParams.get('utm-medium') ||
    lowerCaseParams.get('utmmedium');

  if (isBrowse) {
    initializeIFA(ifa);
    initializeAdId(ifa);
    initializeIFV(ifv);
    initializeUtmParam('utmContent', utmContent || null);
    initializeUtmParam('utmSource', utmSource || null);
    initializeUtmParam('utmCampaign', utmCampaign || null);
    initializeUtmParam('utmMedium', utmMedium || null);
  }

  useEffect(() => {
    initializeJrxDeviceId(latestJrxDeviceId);
  }, [latestJrxDeviceId]);

  useEffect(() => {
    if (!sdkStateFromLs) {
      const sdkStateStr = JSON.stringify({
        ...INITIAL_SDK_STATE,
        appSpecificDeviceId: deviceId,
        ifa,
        ifv,
        utmCampaign,
        utmContent,
        utmMedium,
        utmSource,
      });
      localStorage.setItem(
        biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE,
        sdkStateStr,
      );
      initializeAppIdAndAppToken();
      initializeBlitzDeviceId();
    }
  }, []);

  const getDeviceIdCall = useCallback(async () => {
    try {
      const response = await ApiUser.getDeviceId(ifa, ifv, userAgentStr);

      setDeviceId(response.device.id);
      initializeAppSpecificDeviceId(response.device.id);
      sdkBiEvents.blitzDeviceUpdate();

      localStorage.setItem(
        biConstants.BI_LOCAL_STORAGE_KEYS.DEVICE_DETAILS,
        JSON.stringify(response),
      );
    } catch (e) {
      console.error('error while creating device\n', e);
    }
  }, [ifa, ifv, userAgentStr]);

  useEffect(() => {
    if (!deviceId || deviceId === 'null') {
      getDeviceIdCall();
    }
  }, [deviceId, ifa, ifv, userAgentStr]);

  const updateUtmDetails = async () => {
    try {
      if (referralId) {
        const userId = await ApiUser.getReferralUserData(referralId);
        if (userId) {
          const utmDetailsValue = JSON.parse(utmDetails || '{}');
          const utmMedium =
            utmDetailsValue?.utm_medium ||
            utmDetailsValue?.utmMedium ||
            'appcode';
          blitzUtmDetailsUpdate(
            'knownReferral',
            deviceId,
            'invites',
            referralId,
            utmMedium,
            userId,
          );
        } else {
          blitzUtmDetailsUpdate(
            'unknownReferral',
            deviceId,
            'invites',
            referralId,
            'appcode',
            'UnknownUserReferrer',
          );
        }
      } else if (utmDetails) {
        const utmDetailsValue = JSON.parse(utmDetails || '{}');
        if (utmDetailsValue['utm_source']) {
          const uSource = utmDetailsValue['utm_source'];
          const uCampaign = utmDetailsValue['utm_campaign'];
          const uMedium = utmDetailsValue['utm_medium'];
          const uContent = utmDetailsValue['utm_content'];
          blitzUtmDetailsUpdate(
            'otherThanReferral',
            deviceId,
            uSource.toLowerCase() === 'invites' ? 'organic' : uSource,
            uCampaign?.toLowerCase() === 'null' ? 'organic' : uCampaign,
            uMedium?.toLowerCase() === 'null' ? 'organic' : uMedium,
            uContent?.toLowerCase() === 'null' ? 'organic' : uContent,
          );
        }
      }
    } catch (e) {
      console.error('error while updateUtmDetails', e);
    }
  };

  useEffect(() => {
    if (
      deviceId &&
      deviceId !== 'null' &&
      ((referralId && referralId !== 'null') ||
        (utmDetails && utmDetails !== 'null'))
    ) {
      updateUtmDetails();
    }
  }, [deviceId, referralId, utmDetails]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        commonBiEvents.sessionStart({});
      } else {
        commonBiEvents.sessionLength({});
      }
    };

    commonBiEvents.sessionStart({});
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const saveReferralId = () => {
    if (!isBrowser()) return;
    const params = new URLSearchParams(window.location.search);
    const referralIdFromParam =
      params.get('referral') ||
      params.get('r') ||
      params.get('rH') ||
      params.get('rS') ||
      params.get('rP') ||
      params.get('rR') ||
      params.get('rG') ||
      params.get('r1') ||
      params.get('r2') ||
      params.get('r3');
    const influencerIdFromParam = params.get('influencer') || params.get('i');
    const utmSource =
      params.get('utmSource') ||
      params.get('utmsource') ||
      params.get('utm_source');
    const utmCampaign =
      params.get('utmCampaign') ||
      params.get('utmcampaign') ||
      params.get('utm_campaign');
    let utmMedium =
      params.get('utmMedium') ||
      params.get('utmmedium') ||
      params.get('um') ||
      params.get('utm_medium');
    if (params.get('r1')) {
      utmMedium = '43556';
    } else if (params.get('r2')) {
      utmMedium = '43558';
    } else if (params.get('r3')) {
      utmMedium = '43559';
    }
    const utmContent =
      params.get('utmContent') ||
      params.get('utmcontent') ||
      params.get('utm_content');
    if (referralIdFromParam || influencerIdFromParam) {
      if (referralIdFromParam) {
        /**
         * Hack for Dacheng(username) and randomnameian(referralCode)
         * https://app.superchamps.com/seasons/?r=Dacheng
         * https://app.superchamps.com/seasons/?r=randomnameian
         */
        /**
         * Hack for 0xFEI(username) and IndoorSingingLeopard65(referralCode)
         * Created dummy user with email emailsriharshareddy@gmail.com with username 0xFEI
         * https://app.superchamps.com/seasons/?r=0xFEI
         * https://app.superchamps.com/seasons/?r=IndoorSingingLeopard65
         */
        const storeReferralId =
          referralIdFromParam === 'Dacheng'
            ? 'randomnameian'
            : referralIdFromParam?.toLowerCase() === '0xfei'
            ? 'IndoorSingingLeopard65'
            : referralIdFromParam;
        localStorage.setItem('REFERRAL_ID', storeReferralId);
        setReferralId(storeReferralId);
      }
      if (influencerIdFromParam) {
        /**
         * Hack for Dacheng(username) and randomnameian(referralCode)
         * https://app.superchamps.com/seasons/?r=Dacheng
         * https://app.superchamps.com/seasons/?r=randomnameian
         */
        /**
         * Hack for 0xFEI(username) and IndoorSingingLeopard65(referralCode)
         * Created dummy user with email emailsriharshareddy@gmail.com with username 0xFEI
         * https://app.superchamps.com/seasons/?r=0xFEI
         * https://app.superchamps.com/seasons/?r=IndoorSingingLeopard65
         */
        const storeReferralId =
          influencerIdFromParam === 'Dacheng'
            ? 'randomnameian'
            : influencerIdFromParam?.toLowerCase() === '0xfei'
            ? 'IndoorSingingLeopard65'
            : influencerIdFromParam;
        localStorage.setItem('REFERRAL_ID', storeReferralId);
        setReferralId(storeReferralId);
      }
      localStorage.setItem(
        biConstants.BI_LOCAL_STORAGE_KEYS.UTM_DETAILS,
        JSON.stringify({
          utm_source: utmSource,
          utm_campaign: utmCampaign,
          utm_medium: utmMedium,
          utm_content: utmContent,
        }),
      );
    } else if (utmSource || utmCampaign || utmMedium || utmContent) {
      const utmDetailsVal = JSON.stringify({
        utm_source: utmSource || '',
        utm_campaign: utmCampaign || '',
        utm_medium: utmMedium || '',
        utm_content: utmContent || '',
      });
      localStorage.setItem(
        biConstants.BI_LOCAL_STORAGE_KEYS.UTM_DETAILS,
        utmDetailsVal,
      );
      setUtmDetails(utmDetailsVal);
    }
  };

  useEffect(() => {
    saveReferralId();
  }, [pathname]);

  useEffect(() => {
    saveReferralId();
  }, []);

  return <div />;
}

export default InitBi;
