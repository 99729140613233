import { v4 as uuidv4 } from 'uuid';

import packageJson from '../../package.json';

import { config } from './bi-api';
import { biConstants } from './bi-constants';

const isBrowser = () => typeof window !== 'undefined';

export const sdkStateFromLs: TSdkState | null = JSON.parse(
  isBrowser()
    ? localStorage.getItem(biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE) ||
        'null'
    : 'null',
);
export const ifa = sdkStateFromLs ? sdkStateFromLs.ifa : uuidv4();
export const ifv = sdkStateFromLs ? sdkStateFromLs.ifv : uuidv4();
export const userAgentStr = isBrowser()
  ? window.navigator.userAgent
  : 'UNSET_IN_VERIFY_NUMBER';

export const biUtils = {
  /**
   *
   * @param nfts
   * @returns string of the form ${nftIndex}_${contractId}_${blockchain}-apps:${pipe (|) separated appIds}
   */
  getNftDetailsForBi: (nfts: any[] | null | undefined): string => {
    try {
      const ids = nfts
        ? nfts.reduce(
            (prev, curr) =>
              prev
                ? `${prev},${curr.nft?.nftIndex}_${curr.nft?.contractId}_${curr.nft?.blockchain}`
                : `${curr.nft?.nftIndex}_${curr.nft?.contractId}_${curr.nft?.blockchain}`,
            '',
          )
        : '';
      return ids;
    } catch (e) {
      console.error('nft ID for BI error\n', e);
      return '';
    }
  },
  getAppIdsForBi: (nfts: any[] | null | undefined): string => {
    try {
      const ids = nfts
        ? nfts.reduce(
            (prev, curr) => (prev ? `${curr.appId}` : `${curr.appId}`),
            '',
          )
        : '';
      return ids;
    } catch (e) {
      console.error('app ID for BI error\n', e);
      return 'app ID for BI error';
    }
  },

  getDefaultBiEventPostLogin: () => {
    const defaultBiEvent = localStorage.getItem(
      biConstants.BI_LOCAL_STORAGE_KEYS.DEFAULT_BI_EVENT,
    );
    if (isBrowser() && defaultBiEvent !== null) {
      const data = JSON.parse(defaultBiEvent || '{}');
      data['blitzSessionId'] = localStorage.getItem('blitzsessionId');
      delete data['creation_time'];
      delete data['creationTime'];
      delete data['utmSource'];
      delete data['utmCampaign'];
      delete data['utmMedium'];
      delete data['utmContent'];
      delete data['utm_source'];
      delete data['utm_campaign'];
      delete data['utm_medium'];
      delete data['utm_content'];
      return data;
    }
    return null;
  },

  getDeviceDetailsFromLocalStorage: () => {
    return JSON.parse(
      isBrowser()
        ? localStorage.getItem(
            biConstants.BI_LOCAL_STORAGE_KEYS.DEVICE_DETAILS,
          ) || '{}'
        : '{}',
    );
  },

  getSdkStateFromLocalStorage: () => {
    return JSON.parse(
      isBrowser()
        ? localStorage.getItem(biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE) ||
            '{}'
        : '{}',
    );
  },

  getIsUserNewFromLocalStorage: () => {
    const hours24 = 1000 * 60 * 60 * 24;
    const user = JSON.parse(
      localStorage.getItem(biConstants.BI_LOCAL_STORAGE_KEYS.USER_PROFILE) ||
        'null',
    );
    if (user) {
      return !(user.appUser.createdAt + hours24 < Date.now());
    }
    return true;
  },

  getDefaultBiEventBeforeLogin: () => {
    const DEVICE_DETAILS = JSON.parse(
      isBrowser()
        ? localStorage.getItem(
            biConstants.BI_LOCAL_STORAGE_KEYS.DEVICE_DETAILS,
          ) || '{}'
        : '{}',
    );

    const sdkState = JSON.parse(
      isBrowser()
        ? localStorage.getItem(biConstants.BI_LOCAL_STORAGE_KEYS.SDK_STATE) ||
            '{}'
        : '{}',
    );

    const defaultBiEvent = {
      blitz_app_id: config.blitzAppId,
      blitz_device_id: sdkState.blitzDeviceId || '',
      app_specific_device_id: DEVICE_DETAILS ? DEVICE_DETAILS.device?.id : '',
      device_model: DEVICE_DETAILS ? DEVICE_DETAILS.device?.deviceModel : '',
      app_version: packageJson.version,
      locale_code:
        (navigator.languages && navigator.languages[0]) || navigator.language,
      time_zone: new Date().getTimezoneOffset().toString(),
      platform_code: 'Web',
      user_agent: isBrowser() ? window.navigator.userAgent : '',
    };
    if (isBrowser()) {
      defaultBiEvent['blitzSessionId'] = localStorage.getItem('blitzsessionId');
    }

    delete defaultBiEvent['creation_time'];
    delete defaultBiEvent['creationTime'];
    delete defaultBiEvent['utmSource'];
    delete defaultBiEvent['utmCampaign'];
    delete defaultBiEvent['utmMedium'];
    delete defaultBiEvent['utmContent'];
    delete defaultBiEvent['utm_source'];
    delete defaultBiEvent['utm_campaign'];
    delete defaultBiEvent['utm_medium'];
    delete defaultBiEvent['utm_content'];
    return defaultBiEvent;
  },
};
