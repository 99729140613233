import React, { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';

import SuperChampsProvider from '@/context/superchamps-context';

import './src/assets/css/bootstrap.min.css';
import './src/assets/css/global.css';
import './src/assets/css/style.css';
import './src/assets/fonts/font.css';
import './src/assets/css/media.css';
import 'react-tooltip/dist/react-tooltip.css';

import InitBi from './src/components/init-bi';
import AuthProvider from './src/context/auth-context';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Fragment>
        <InitBi />
        <AuthProvider>
          <SuperChampsProvider>{element}</SuperChampsProvider>
        </AuthProvider>
        <ToastContainer theme="dark" limit={1} />
      </Fragment>
    </>
  );
};
