import CONSTANTS from '@/common/constants';
import { JR_ENV } from '@/common/constants/env';
import utils, { localStorage } from '@/common/utils';
import {
  TLastSeasonData,
  TSocialDataVerificationResponse,
} from '@/features/quests/types';

import { httpClient } from './http-client';

export const generateRandomText = (length: number) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let randomText = '';

  for (let i = 0; i < length; i++) {
    const randomCharacter =
      characters[Math.floor(Math.random() * characters.length)];
    randomText += randomCharacter;
  }

  return randomText;
};

/**
 * Generates code verifier for twitter and discord connect
 * @returns code verifier
 */
const generateCodeVerifier = () => {
  const base64URLEncode = (str: string) => {
    // eslint-disable-next-line
    return str
      .toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  };
  const token = generateRandomText(64);
  return base64URLEncode(token);
};
console.log('generateCodeVerifier', generateCodeVerifier());

export const getDataVersions = async () => {
  const prodHost = utils.isProd() ? 'https://content.superchamps.com' : '';
  try {
    const { data } = await httpClient.get(
      `${prodHost}/unclassified/apps/${CONSTANTS.JRX_APP_ID}/dataVersions`,
    );
    return data;
  } catch (e) {
    console.error('Error in getDataVersions\n', e);
  }
};

export const getQuestsApi = async (
  currentVersion: number,
  newVersion: number,
) => {
  const prodHost = utils.isProd() ? 'https://content.superchamps.com' : '';
  const refVersion = newVersion;
  const q = `currentVersion=${currentVersion}&refVersion=${refVersion}&appId=${CONSTANTS.JRX_APP_ID}&platform=WEB`;
  const { data } = await httpClient.get(
    `${prodHost}/gamechallenge/countryCode/US/getChallenges?${q}`,
  );

  // set new version to localStorage
  if (data) {
    localStorage.setItem('QUESTS', JSON.stringify(data));
    localStorage.setItem('QUESTS_DATA_VERSION', data.version);
  }

  return data;
};

export const getLeaderboardUsersApi = async () => {
  const prodHost = utils.isProd() ? 'https://content.superchamps.com' : '';
  const q = `type=${'Quest_Points'}&appId=${CONSTANTS.JRX_APP_ID}`;
  const { data } = await httpClient.get(
    `${prodHost}/gamechallenge/champs-season-leaderboard/rankedUserData?${q}`,
  );
  // const response = await fetch('/ranks.json');
  // const data = await response.json();

  // set new version to localStorage
  if (data) {
    console.log('data', data);
  }

  return data;
};

export const updateBadgeStatus = async (userId: string, payload: any) => {
  console.log('httpClient', httpClient);
  console.log('payload', payload);
  const response = await httpClient.put(
    `/gamechallenge/champs-season-leaderboard/user/${userId}/updateBadgeStatus`,
    payload,
  );
  return response.status;
};

export const newQuestsDataVersion = async () => {
  try {
    const versions: any = await getDataVersions();
    if (
      versions.staticDataVersions.CHALLENGES !==
        parseInt(localStorage.getItem('QUESTS_DATA_VERSION') || '', 10) ||
      !localStorage.getItem('QUESTS')
    ) {
      return versions.staticDataVersions.CHALLENGES;
    }
    return false;
  } catch (e) {
    console.error('Error in getQuests\n', e);
  }
};

function injectJsonIntoNestedArray(target: any, source: any, keyPath: any[]) {
  let targetArray = target;
  for (let i = 0; i < keyPath.length - 1; i++) {
    targetArray = targetArray[keyPath[i]];
    if (!targetArray) {
      console.error(
        `The key path '${keyPath.slice(0, i + 1).join('.')}' does not exist.`,
      );
      return;
    }
  }
  if (!Array.isArray(targetArray[keyPath[keyPath.length - 1]])) {
    console.error(`The key '${keyPath.join('.')}' does not contain an array.`);
    return;
  }
  targetArray[keyPath[keyPath.length - 1]].push(source);
}

function getJsonObject(target: any, keyPath: any[]) {
  let targetArray = target;
  for (let i = 0; i < keyPath.length - 1; i++) {
    targetArray = targetArray[keyPath[i]];
    if (!targetArray) {
      console.error(
        `The key path '${keyPath.slice(0, i + 1).join('.')}' does not exist.`,
      );
      return null;
    }
  }
  return targetArray[keyPath[keyPath.length - 1]];
}

const stakedNftQuestPoints = 200;
const stakedNftDurationQuestPoints = 10;
const nftHodlSeasonData = {
  id: 10002,
  challengeType: 'regular',
  challengeName: 'HEAD START HERO',
  challengeDescription: 'Hold Genesis NFTs for more than a year',
  challengeKey: {
    challengeTask: 'headStartHodler',
    cashFlag: 'all',
    tournamentNameId: null,
    secondaryScoreIndex: null,
    gameModeOnly: false,
    secondaryParameter: '3b5UMF',
    characterType: 'BOTH',
    version: 'V1',
    tournamentType: 'all',
  },
  challengeSecondaryKey: {
    challengeTask: 'headStartHodler',
    maxProgression: 0,
    progressionForLevel: 1.0,
    maxPoints: 0,
    pointsToGainForInvite: 0,
    broadcastSourceAppIds: null,
    playStyles: null,
    champRarities: null,
    socialHandle: null,
    shouldHoldSoulBoundNft: false,
    tweetMetricFlag: null,
    tweetContent: null,
  },
  eventName: 'V1_headStartHodler_all_all_all_all_false_3b5UMF_BOTH',
  heroChallenge: false,
  challengeRedirection: 'https://opensea.io/collection/tennis-champs-genesis',
  challengeLevelsList: [
    {
      id: 72924,
      levelNo: 1,
      parameter: 1,
      giftbox: 'none',
      rewardsList: [
        {
          dailyRewardType: 'CPPoints',
          amount: 750,
          currencyCode: 'CHAMP',
          currencyType: '',
          duration: 0,
          reward: null,
        },
      ],
      adsRewards: null,
    },
  ],
  jsonLogicFilters: '',
  placementPriority: 8,
  extraTimeBeforeStart: 0,
  challengesUiMetaData: {
    infoPopup:
      'The referred user must earn 70 points in order for you to receive your points',
    ctaText: 'OPENSEA',
    placementName: 'genesis',
    sectionName: 'seasonal',
    priority: 8,
    iconUrl: 'https://assets.onjoyride.com/onjoyride.champswebCrypt/nft.png',
    caption: 'year',
  },
};
const nftOwnerSeasonData = {
  id: 10001,
  challengeType: 'regular',
  challengeName: 'GENESIS NFT OWNERS',
  challengeDescription: 'Own a Genesis NFT',
  challengeKey: {
    challengeTask: 'headStart',
    cashFlag: 'all',
    tournamentNameId: null,
    secondaryScoreIndex: null,
    gameModeOnly: false,
    secondaryParameter: '9xwmEA',
    characterType: 'BOTH',
    version: 'V1',
    tournamentType: 'all',
  },
  challengeSecondaryKey: {
    challengeTask: 'headStart',
    maxProgression: 0,
    progressionForLevel: 1.0,
    maxPoints: 0,
    pointsToGainForInvite: 0,
    broadcastSourceAppIds: null,
    playStyles: null,
    champRarities: null,
    socialHandle: null,
    shouldHoldSoulBoundNft: false,
    tweetMetricFlag: null,
    tweetContent: null,
  },
  eventName: 'V1_headStart_all_all_all_all_false_9xwmEA_BOTH',
  heroChallenge: false,
  challengeRedirection: 'https://opensea.io/collection/tennis-champs-genesis',
  challengeLevelsList: [
    {
      id: 72923,
      levelNo: 1,
      parameter: 1,
      giftbox: 'none',
      rewardsList: [
        {
          dailyRewardType: 'CPPoints',
          amount: 2000,
          currencyCode: 'CHAMP',
          currencyType: '',
          duration: 0,
          reward: null,
        },
      ],
      adsRewards: null,
    },
  ],
  jsonLogicFilters: '',
  placementPriority: 7,
  startAt: -1,
  extraTimeBeforeStart: 0,
  challengesUiMetaData: {
    infoPopup:
      'The referred user must earn 70 points in order for you to receive your points',
    ctaText: 'OPENSEA',
    placementName: 'genesis',
    sectionName: 'seasonal',
    priority: 7,
    iconUrl: 'https://assets.onjoyride.com/onjoyride.champswebCrypt/nft.png',
    caption: 'NFT',
  },
};
const featuredNftOwnerSeasonData = {
  id: 10005,
  challengeType: 'regular',
  challengeName: 'THE GENESIS CHAPTER',
  challengeDescription: 'Hold an NFT from the Super Champs Genesis Collection',
  challengeKey: {
    challengeTask: 'headStart',
    cashFlag: 'all',
    tournamentNameId: null,
    secondaryScoreIndex: null,
    gameModeOnly: false,
    secondaryParameter: '9xwmEB',
    characterType: 'BOTH',
    version: 'V1',
    tournamentType: 'all',
  },
  challengeSecondaryKey: {
    challengeTask: 'headStart',
    maxProgression: 0,
    progressionForLevel: 1.0,
    maxPoints: 10000,
    pointsToGainForInvite: 0,
    broadcastSourceAppIds: null,
    playStyles: null,
    champRarities: null,
    socialHandle: null,
    shouldHoldSoulBoundNft: false,
    tweetMetricFlag: null,
    tweetContent: null,
  },
  eventName: 'V1_headStart_all_all_all_all_false_9xwmEB_BOTH',
  heroChallenge: false,
  challengeRedirection: 'https://opensea.io/collection/tennis-champs-genesis',
  challengeLevelsList: [
    {
      id: 72923,
      levelNo: 1,
      parameter: 1,
      giftbox: 'none',
      rewardsList: [
        {
          dailyRewardType: 'CPPoints',
          amount: 10000,
          currencyCode: 'CHAMP',
          currencyType: '',
          duration: 0,
          reward: null,
        },
      ],
      adsRewards: null,
    },
  ],
  jsonLogicFilters: '',
  placementPriority: 16,
  startAt: -1,
  extraTimeBeforeStart: 0,
  challengesUiMetaData: {
    infoPopup:
      'The referred user must earn 70 points in order for you to receive your points',
    ctaText: 'OPENSEA',
    placementName: 'hero',
    sectionName: 'seasonal',
    priority: 1,
    iconUrl: 'https://assets.onjoyride.com/onjoyride.champswebCrypt/nft.png',
    caption: '',
  },
};

const sourceStakeNftSeasonData = {
  id: 10000,
  challengeType: 'regular',
  challengeName: "STAKE 'N QUAKE",
  challengeDescription: 'Stake a Genesis NFT',
  challengeKey: {
    challengeTask: 'stakeNft',
    cashFlag: 'all',
    tournamentNameId: null,
    secondaryScoreIndex: null,
    gameModeOnly: false,
    secondaryParameter: 'b64Sig',
    characterType: 'BOTH',
    version: 'V1',
    tournamentType: 'all',
  },
  challengeSecondaryKey: {
    challengeTask: 'twitterRetweet',
    maxProgression: 0,
    progressionForLevel: 1.0,
    maxPoints: 0,
    pointsToGainForInvite: 0,
    playStyles: null,
    champRarities: null,
    socialHandle: null,
    shouldHoldSoulBoundNft: false,
    tweetMetricFlag: null,
    tweetContent: null,
  },
  eventName: 'V1_Staking_all_all_all_all_false_b64Sig_BOTH',
  heroChallenge: false,
  challengeRedirection: '#',
  challengeLevelsList: [
    {
      id: 100000,
      levelNo: 1,
      parameter: 1,
      giftbox: 'none',
      rewardsList: [
        {
          dailyRewardType: 'CPPoints',
          amount: 200,
          currencyCode: 'CHAMP',
          currencyType: '',
          duration: 0,
          reward: null,
        },
      ],
      adsRewards: null,
    },
  ],
  jsonLogicFilters: '',
  placementPriority: 30,
  extraTimeBeforeStart: 0,
  challengesUiMetaData: {
    infoPopup:
      'The referred user must earn 70 points in order for you to receive your points',
    ctaText: 'STAKE',
    placementName: 'genesis',
    sectionName: 'seasonal',
    priority: 30,
    iconUrl: 'https://assets.onjoyride.com/onjoyride.champswebCrypt/nft.png',
    caption: 'NFT',
    pointsPerDay: stakedNftDurationQuestPoints,
  },
};
export const getQuests = async (userUtmSource: string) => {
  const newVersion = await newQuestsDataVersion();
  let data;

  if (newVersion) {
    data = await getQuestsApi(
      parseInt(localStorage.getItem('QUESTS_DATA_VERSION') || '', 0),
      newVersion,
    );
  } else {
    data = JSON.parse(localStorage.getItem('QUESTS') || '');
  }

  // Inject sourceJson into the nested array at the specified key path
  const targetKeyPath = [
    'data',
    'seasonChallengeDTOList',
    0,
    'seasonChallengeList',
  ];
  injectJsonIntoNestedArray(data, sourceStakeNftSeasonData, targetKeyPath);
  const target = getJsonObject(data, targetKeyPath);
  if (target !== null) {
    if (Array.isArray(target)) {
      let hasHodlerQuest = false;
      let hasNftOwnerQuest = false;
      target.forEach((challenge) => {
        if (challenge['eventName'].includes('_headStartHodler_')) {
          hasHodlerQuest = true;
        }
        if (challenge['eventName'].includes('_headStart_')) {
          hasNftOwnerQuest = true;
        }
      });
      if (!hasHodlerQuest) {
        injectJsonIntoNestedArray(data, nftHodlSeasonData, targetKeyPath);
      }
      if (!hasNftOwnerQuest) {
        injectJsonIntoNestedArray(data, nftOwnerSeasonData, targetKeyPath);
        injectJsonIntoNestedArray(
          data,
          featuredNftOwnerSeasonData,
          targetKeyPath,
        );
      }
    }
  }

  return data;
};

export const sourceStakeNftPoints = {
  parameterProgressValue: 1.0,
  eventName: 'V1_Staking_all_all_all_all_false_b64Sig_BOTH',
  lastCompletedLevel: 1,
  lastRedeemedLevel: 1,
  challengeComplete: false,
  pointsEarned: 0.0,
  maxPoints: 0.0,
};
export const sourceNftOwnerPoints = {
  parameterProgressValue: 1.0,
  eventName: 'V1_headStart_all_all_all_all_false_9xwmEA_BOTH',
  lastCompletedLevel: 1,
  lastRedeemedLevel: 1,
  challengeComplete: false,
  pointsEarned: 0.0,
  maxPoints: 0.0,
};
export const sourceFeaturedNftOwnerPoints = {
  parameterProgressValue: 1.0,
  eventName: 'V1_headStart_all_all_all_all_false_9xwmEB_BOTH',
  lastCompletedLevel: 1,
  lastRedeemedLevel: 1,
  challengeComplete: false,
  pointsEarned: 10000.0,
  maxPoints: 0.0,
};
export const sourceNftHodlPoints = {
  parameterProgressValue: 1.0,
  eventName: 'V1_headStartHodler_all_all_all_all_false_3b5UMF_BOTH',
  lastCompletedLevel: 1,
  lastRedeemedLevel: 1,
  challengeComplete: false,
  pointsEarned: 0.0,
  maxPoints: 0.0,
};

export const updateDailyLoginQuest = async (userId: string) => {
  await httpClient.post(
    `/gamechallenge/apps/${CONSTANTS.JRX_APP_ID}/users/${userId}/countryIso/US/updateChallengeEvent`,
    {
      platform: 'Web',
      primaryEvent: 'gameLoad',
      appId: CONSTANTS.JRX_APP_ID,
    },
  );
};

export const getUsersQuestsProgression = async (
  userId: string,
  stakedNftIdCount: number,
  userStakedNftIdData: bigint[],
) => {
  try {
    const { data } = await httpClient.get(
      `/gamechallenge/apps/${CONSTANTS.JRX_APP_ID}/users/${userId}/countryIso/US/getUserChallengeData`,
    );
    const currentDate = new Date();
    // Get the current epoch time in milliseconds
    const currentEpoch = Math.floor(currentDate.getTime() / 1000);
    sourceStakeNftPoints.pointsEarned = stakedNftIdCount * stakedNftQuestPoints;
    userStakedNftIdData.forEach((stakedTime) => {
      const duration = BigInt(currentEpoch) - stakedTime;
      const countDay = JR_ENV === 'production' ? 86400 : 300;
      const days = Math.floor(Number(duration / BigInt(countDay)));
      sourceStakeNftPoints.pointsEarned += days * stakedNftDurationQuestPoints;
    });
    const targetKeyPath = ['seasonChallengeList'];
    injectJsonIntoNestedArray(data, sourceStakeNftPoints, targetKeyPath);

    const target = getJsonObject(data, targetKeyPath);
    if (target !== null) {
      if (Array.isArray(target)) {
        let hasHodlerQuest = false;
        let hasNftOwnerQuest = false;
        target.forEach((challenge) => {
          if (challenge['eventName'].includes('_headStartHodler_')) {
            hasHodlerQuest = true;
          }
          if (challenge['eventName'].includes('_headStart_')) {
            hasNftOwnerQuest = true;
          }
        });
        if (!hasHodlerQuest) {
          sourceNftHodlPoints.pointsEarned = Number(
            data['headStartPoints']['holdingTimePoints'],
          );
          injectJsonIntoNestedArray(data, sourceNftHodlPoints, targetKeyPath);
        }
        if (!hasNftOwnerQuest) {
          sourceNftOwnerPoints.pointsEarned = Number(
            data['headStartPoints']['nftPoints'],
          );
          injectJsonIntoNestedArray(data, sourceNftOwnerPoints, targetKeyPath);
          sourceFeaturedNftOwnerPoints.pointsEarned =
            sourceNftOwnerPoints.pointsEarned > 0 ? 10000 : 0;
          injectJsonIntoNestedArray(
            data,
            sourceFeaturedNftOwnerPoints,
            targetKeyPath,
          );
        }
        if (hasHodlerQuest && hasNftOwnerQuest) {
          data['totalCp'] = data['totalCp'] + sourceStakeNftPoints.pointsEarned;
        } else {
          data['lastSeasonTotalCp'] =
            data['lastSeasonTotalCp'] +
            sourceStakeNftPoints.pointsEarned +
            sourceNftHodlPoints.pointsEarned +
            sourceNftOwnerPoints.pointsEarned;
        }
        data['totalCp'] =
          data['totalCp'] +
          sourceFeaturedNftOwnerPoints.pointsEarned *
            (1 + data['nftMultiplierPercentage'] / 100);
      }
    }
    // console.log('🚀 ~ user progression data along with injection:', data);
    return data;
  } catch (e) {
    console.error('Error in getUsersQuestsProgression\n', e);
  }
};

export const connectTwitter = async (userId: string) => {
  try {
    const url = `/unclassified/integrations/twitter/apps/${CONSTANTS.JRX_APP_ID}/user/${userId}/getAuthorisationUrl`;
    const codeVerifier = generateCodeVerifier();
    localStorage.setItem('TWITTER_CODE_VERIFIER', codeVerifier);
    const { data } = await httpClient.post(url, {
      codeVerifier,
      appId: CONSTANTS.JRX_APP_ID,
    });
    return data;
  } catch (e) {
    console.error('Error in connectTwitter\n', e);
  }
};

export const connectDiscord = async () => {
  try {
    const url = `/unclassified/integrations/discord/getAuthorizationUrl`;
    const codeVerifier = generateCodeVerifier();
    localStorage.setItem('DISCORD_CODE_VERIFIER', codeVerifier);
    const { data } = await httpClient.post(url, { codeVerifier });
    return data;
  } catch (e) {
    console.error('Error in connectDiscord\n', e);
  }
};

export const connectYoutube = async () => {
  try {
    const url = `/unclassified/integrations/youtube/getAuthorizationUrl`;
    const { data } = await httpClient.get(url);
    return data;
  } catch (e) {
    console.error('Error in connectYoutube\n', e);
  }
};

export const validateAndUpdateReferralCode = async (
  userId: string,
  paylaod: {
    nftId: string;
    referralCode: string;
  },
) => {
  try {
    /**
     * Hack for Dacheng(username) and randomnameian(referralCode)
     * https://app.superchamps.com/seasons/?r=Dacheng
     * https://app.superchamps.com/seasons/?r=randomnameian
     */
    /**
     * Hack for 0xFEI(username) and IndoorSingingLeopard65(referralCode)
     * Created dummy user with email emailsriharshareddy@gmail.com with username 0xFEI
     * https://app.superchamps.com/seasons/?r=0xFEI
     * https://app.superchamps.com/seasons/?r=IndoorSingingLeopard65
     */
    const referralCodeParam =
      paylaod.referralCode === 'Dacheng'
        ? 'randomnameian'
        : paylaod.referralCode?.toLowerCase() === '0xfei'
        ? 'IndoorSingingLeopard65'
        : paylaod.referralCode;
    const url = `/user-management/users/${userId}/apps/${CONSTANTS.JRX_APP_ID}/updateReferralCode`;
    const { data } = await httpClient.post(url, {
      nftId: paylaod.nftId,
      referralCode: referralCodeParam,
      platform: 'WEB',
    });
    return data;
  } catch (e) {
    console.error('Error in validateAndUpdateReferralCode\n', e);
  }
};

export const verifyTwitterAction = async (userId: string, payload: any) => {
  try {
    const url = `/unclassified/integrations/twitter/apps/${CONSTANTS.JRX_APP_ID}/user/${userId}/verifyUserDataForAction`;
    const { data } = await httpClient.post(url, payload);
    return data;
  } catch (e) {
    console.error('Error in verifyTwitterAction\n', e);
  }
};

export const verifyUserAction = async (userId: string, payload: any) => {
  try {
    const url = `/unclassified/integrations/social/apps/${CONSTANTS.JRX_APP_ID}/user/${userId}/verifyUserDataForAction`;
    const { data } = await httpClient.post(url, payload);
    return data;
  } catch (e) {
    console.error('Error in verifyTwitterAction\n', e);
  }
};

export const verifyDiscordAction = async (userId: string, payload: any) => {
  // sample payload
  // {
  //   "discordUserId": "string",
  //   "discordRoleId": "string",
  //   "discordGuildId": "string",
  //   "actionType": "VERIFY_ROLE",
  //   "platform": "WEB",
  //   "refreshToken": "string"
  // }
  try {
    const url = `/unclassified/integrations/discord/apps/${CONSTANTS.JRX_APP_ID}/user/${userId}/verifyAction`;
    const { data } = await httpClient.post(url, payload);
    return data;
  } catch (e) {
    console.error('Error in verifyDiscordAction\n', e);
  }
};

export const verifyYoutubeAction = async (userId: string, payload: any) => {
  // sample payload
  // {
  //   "socialLink": "string",
  //   "socialActivityType": "string",
  //   "platform": "iOS"
  // }
  try {
    const url = `/unclassified/integrations/youtube/apps/${CONSTANTS.JRX_APP_ID}/user/${userId}/verifyAction`;
    const { data } = await httpClient.post(url, payload);
    return data;
  } catch (e) {
    console.error('Error in verifyYoutubeAction\n', e);
  }
};

export const verifySoulBoundNft = async (userId: string) => {
  try {
    const url = `/unclassified/apps/${CONSTANTS.JRX_APP_ID}/user/${userId}/verifyAccessPassNft?platform=Web`;
    const { data } = await httpClient.put(url);
    return data;
  } catch (e) {
    console.error('Error in verifySoulBoundNft\n', e);
  }
};

export const updateKyc = async (
  userId: string,
  personaVerificationId: string,
) => {
  try {
    const url = `/unclassified/apps/${CONSTANTS.JRX_APP_ID}/user/${userId}/updateKyc?personaVerificationId=${personaVerificationId}&platform=Web`;
    const { data } = await httpClient.put(url);
    return data;
  } catch (e) {
    console.error('Error in updateKyc\n', e);
  }
};
export const getSocialData = async (
  userId: string,
): Promise<TSocialDataVerificationResponse | undefined> => {
  try {
    const url = `/unclassified/apps/${CONSTANTS.JRX_APP_ID}/user/${userId}/socialData`;
    const { data } = await httpClient.get(url);
    return data;
  } catch (e) {
    console.error('Error in getSocialData\n', e);
  }
};

export const verifyDiscordConnect = async (payload: {
  code: string;
  codeVerifier: string;
  userId: string;
  appId: string;
  platform: string;
}): Promise<any> => {
  try {
    const url = `/unclassified/integrations/discord/connect`;
    const { data } = await httpClient.post(url, payload);
    return data;
  } catch (e) {
    console.error('Error in verifyDiscordConnect\n', e);
    throw e;
  }
};

export const verifyYoutubeConnect = async (payload: {
  code: string;
  codeVerifier: string;
  userId: string;
  appId: string;
  platform: string;
}): Promise<any> => {
  try {
    const url = `/unclassified/integrations/youtube/connect`;
    const { data } = await httpClient.post(url, payload);
    return data;
  } catch (e) {
    console.error('Error in verifyYoutubeConnect\n', e);
    throw e;
  }
};

export const verifyTwitterConnect = async (
  userId: string,
  payload: {
    authCode: string;
    appId: string;
  },
): Promise<any> => {
  try {
    const url = `/unclassified/integrations/twitter/apps/${CONSTANTS.JRX_APP_ID}/user/${userId}/connectToTwitterViaOAuth2`;
    const { data } = await httpClient.post(url, payload);
    return data;
  } catch (e) {
    console.error('Error in verifyTwitterConnect\n', e);
    throw e;
  }
};

export const getLastSeasonData = async (
  userId: string,
): Promise<TLastSeasonData> => {
  try {
    /*const url = `/gamechallenge/apps/${CONSTANTS.JRX_APP_ID}/users/${userId}/lastSeasonData`;
    const { data } = await httpClient.get(url);
    return data;*/
    return {};
  } catch (e) {
    console.error('Error in getLastSeasonData\n', e);
    throw e;
  }
};

export const getReferralCount = async (userId: string) => {
  try {
    const url = `/user-management/users/${userId}/referralCount`;
    const { data } = await httpClient.get(url);
    return data;
  } catch (e) {
    console.error('Error in getReferralCount\n', e);
    throw e;
  }
};

export const updateBadgeStatusV2 = async (userId: string, payload: any) => {
  console.log('httpClient', httpClient);
  console.log('payload', payload);
  const response = await httpClient.put(
    `/gamechallenge/champs-season-leaderboard/user/${userId}/updateBadgeStatus`,
    payload,
  );
  return response.status;
};

export const verifyCoinbaseConnect = async (
  userId: string,
  payload: {
    signature: string;
    address: string;
    nonceTimeStamp: number;
    blockchain: string;
    signatureBlockchain: string;
    walletName: string;
    platform: string;
  },
): Promise<any> => {
  try {
    const url = `/wallet/app/${CONSTANTS.JRX_APP_ID}/user/${userId}/connectWalletQuestUpdate`;
    const { data } = await httpClient.post(url, payload);
    return data;
  } catch (e) {
    console.error('Error in verifyCoinbaseConnect\n', e);
    throw e;
  }
};
