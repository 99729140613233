import packageJson from '../../package.json';

import { TSdkState } from './bi-types';

export const biConstants = {
  APP_VERSION: packageJson.version,
  /** todo localStorage keys should be in common constants */
  BI_LOCAL_STORAGE_KEYS: {
    USER_PROFILE: 'USER_PROFILE',
    DEVICE_DETAILS: 'DEVICE_DETAILS',
    DEFAULT_BI_EVENT: 'DEFAULT_BI_EVENT',
    SDK_STATE: 'SDK_STATE',
    UTM_DETAILS: 'UTM_DETAILS',
  },
};

export const biFields = {
  eventNames: {
    WEB_ONBOARDING: 'web_onboarding',
    WEB_VIEW: 'web_view',
    SESSION_START: 'blitz_session_start',
    SESSION_LENGTH: 'blitz_session_length',
    WALLET_FLOW: 'wallet_flow',
    EXTERNAL_WALLET_ADDRESS: 'external_wallet_address',
    COLLECTIBLES_FLOW: 'collectibles_flow',
    WEB_MARKETPLACE: 'web_marketplace',
    META_GAME: 'meta_game',
    FB_CONVERSION_PIXEL: 'fb_conversion_pixel',
  },

  strField0Types: {
    REGISTER_DEVICE: 'register_device',
    REGISTRATION: 'registration',
    VIEW_OPEN: 'view_open',
    CLICK: 'click',
    STATE_CHANGE: 'state_change',
    UPDATE: 'update',
    POPUP: 'popup',
  },

  strField1Types: {
    SUCCESS_FAILED: 'success/failed',
    TAB_NAME: 'tab_name',
    BUY: 'buy',
    SIGNUP: 'sign_up',
    LOGIN: 'login',
    PHONE_NUMBER_SCREEN: 'phone_number_screen',
    COUNTRY_PICKER: 'country_picker',
    INVALID_NUMBER: 'invalid_number',
    GET_OTP: 'get_otp',
    ERROR: 'error',
    PHONE_NUMBER_OTP_SCREEN: 'phone_number_otp_screen',
    EMAIL_OTP_SCREEN: 'email_otp_screen',
    OTP_ENTERED: 'otp_entered',
    CONFIRM: 'confirm',
    INCORRECT_OTP_ENTERED: 'incorrect_otp_entered',
    RESEND: 'resend',
    OTP_ON_CALL: 'otp_on_call',
    OTP_VERIFIED: 'otp_verified',
    NAME_EMAIL_SCREEN: 'name_email_screen',
    FIRST_NAME_ENTERED: 'first_name_entered',
    LAST_NAME_ENTERED: 'last_name_entered',
    EMAIL_ENTERED: 'email_entered',
    EMAIL_CONFIRMED: 'email_confirmed',
    CONTINUE: 'continue',
    ADDRESS_CREATED: 'address_created',
    connect_wallet: 'connect_wallet',
    signin_wallet: 'signin_wallet',
    wallet_connected: 'wallet_connected',
    wallet_sign_in: 'wallet_sign_in',
  },

  strField2Types: {
    PHONE_NUMBER_SCREEN: 'phone_number_screen',
    PHONE_NUMBER_EMAIL_SCREEN: 'phone_number_email_screen',
    NAME_EMAIL_SCREEN: 'name_email_screen',
    PHONE_NUMBER_OTP_SCREEN: 'phone_number_otp_screen',
    EMAIL_OTP_SCREEN: 'email_otp_screen',
    JRX_WALLET: 'jrx_wallet',
    account_balance_wallet: 'account_balance_wallet',
  },

  strField3Types: {
    WEB: 'web',
  },

  strField4Types: {
    NEW: 'new',
    RETURNING: 'returning',
  },

  strField6Types: {
    metamask: 'metamask',
  },

  strField8Types: {
    FIREBASE: 'firebase',
    TWILIO: 'twilio',
  },
};

export const INITIAL_SDK_STATE: TSdkState = {
  blitzAppId: '',
  appToken: '',
  isAdTracking: true,
  baseUrl: '',
  deviceType: 'WEB',
  appSpecificDeviceId: '',
  blitzUserId: '',
  deviceId: null,
  blitzDeviceId: '',
  installAppVersion: '',
  ifa: '',
  ifv: '',
  adId: '',
  GAID: '',
  utmSource: null,
  utmCampaign: null,
  utmMedium: null,
  utmContent: null,
};

export enum EBoolean {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export enum EWebsiteTabNames {
  ACCOUNT_BALANCE_WALLET = 'account_balance_wallet',
  COLLECTIBLES = 'collectibles',
  HISTORY = 'history',
  LOGIN = 'login',
  SIGNUP = 'signup',
  RACKET_RAMPAGE = 'racket_rampage',
}

export enum ECtaNames {
  SIGN_UP = 'sign_up',
  LOGIN = 'login',
  BUY = 'buy',
  LEARN_MORE = 'learn_more',
  RESEND_OTP = 'resend_otp',
  ONBOARDING = 'onboarding',
  TRANSFER = 'transfer',
  CONTINUE = 'continue',
  SUBMIT = 'submit',
  TOGGLE = 'toggle',
  COPY_TO_CLIPBOARD = 'copy_to_clipboard',
  // DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'withdraw',
  GO_BACK = 'go_back',
  LOGOUT = 'logout',
  DOWNLOAD_REDIRECTION = 'download_redirection',
  RR_BANNER_CTA = 'banner_cta',
  QUEST_CTA = 'quest_cta',
}

export enum EChallengType {
  DAILY = 'daily',
  SEASON = 'season',
  LIFETIME = 'lifetime',
}

export enum EQuestTriggerTab {
  HOME_TAB = 'home_tab',
  SEASON_TAB = 'season_tab',
}

export const PLATFORM = 'SUPERCHAMPS';
export const BI_CURRENCY = 'SLAM';
