import moment from 'moment-timezone';
import React, {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  getLeaderboardUsersApi,
  getQuests,
  getUsersQuestsProgression,
  updateDailyLoginQuest,
} from '@/api/quests';
import { biConstants } from '@/bi/bi-constants';
import utils from '@/common/utils';
import { INotification } from '@/components/common/notification-modal';
import { ModalRef } from '@/components/modal/modal';
import {
  TTask,
  TSeason,
  TStakeModal,
  SeasonStateEnum,
  TUserProgression,
  TProfileImageModal,
} from '@/features/quests/types';
import useLocation from '@/hooks/use-location';

import { useAuth } from './auth-context';

export interface ISuperChampsContext {
  season: TSeason | undefined;
  setSeason: Dispatch<SetStateAction<TSeason | undefined>>;
  seasonState: SeasonStateEnum | undefined;
  setSeasonState: Dispatch<SetStateAction<SeasonStateEnum | undefined>>;
  getSeasonState: () => void;
  isSeasonLoading: boolean;
  setIsSeasonLoading: Dispatch<SetStateAction<boolean>>;
  userProgression: TUserProgression | undefined;
  setUserProgression: Dispatch<SetStateAction<TUserProgression | undefined>>;
  getUserQuestsData: () => void;
  notification: INotification;
  setNotification: Dispatch<SetStateAction<INotification>>;
  completedQuestsCount: (dayIndex: number) => {
    daily: number;
    seasonal: number;
  };
  twitterMetricTask: TTask | null;
  setTwitterMetricTask: Dispatch<SetStateAction<TTask | null>>;
  stakeModal: TStakeModal;
  setStakeModal: Dispatch<SetStateAction<TStakeModal>>;
  showStakingRewards: boolean;
  setShowStakingRewards: Dispatch<SetStateAction<boolean>>;
  currentQuest: MutableRefObject<TTask | null>;
  blQrCode: RefObject<ModalRef>;
  rrQrCode: RefObject<ModalRef>;
  thirdPartyPoints: number | undefined;
  setThirdPartyPoints: Dispatch<SetStateAction<number | undefined>>;
  userUtmSource: string;
  setUserUtmSource: Dispatch<SetStateAction<string>>;
  profileImageModal: TProfileImageModal;
  setProfileImageModal: Dispatch<SetStateAction<TProfileImageModal>>;
  responseLeaderboard: any;
  setShowQPModal: Dispatch<SetStateAction<boolean>>;
  showQPModal: boolean;
  userRank: number;
  nextRefreshTime: number;
}
const SuperChampsContext = createContext({} as ISuperChampsContext);

const SuperChampsProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, userStakedNftIds, userStakedNftIdData } = useAuth();
  const { pathname } = useLocation();
  const [season, setSeason] = useState<TSeason | undefined>();
  const [nextRefreshTime, setNextRefreshTime] = useState<number>();
  const [showQPModal, setShowQPModal] = useState<boolean>(false);
  const [stakeModal, setStakeModal] = useState<TStakeModal>({
    show: false,
    type: 'stake',
  });
  const [profileImageModal, setProfileImageModal] =
    useState<TProfileImageModal>({
      show: false,
      type: 'twitter',
    });
  const blQrCode = useRef<ModalRef>(null);
  const rrQrCode = useRef<ModalRef>(null);
  const [showStakingRewards, setShowStakingRewards] = useState<boolean>(false);
  const [seasonState, setSeasonState] = useState<SeasonStateEnum>();
  const [isSeasonLoading, setIsSeasonLoading] = useState(true);
  const [userProgression, setUserProgression] = useState<
    TUserProgression | undefined
  >();
  const [userRank, setUserRank] = useState<number>(-1);
  const [userUtmSource, setUserUtmSource] = useState<string>('organic');
  const [notification, setNotification] = useState<INotification>({
    type: '',
    show: false,
    title: '',
    message: '',
    className: '',
    isLoading: false,
    ctaText: 'Okay',
  });
  const [twitterMetricTask, setTwitterMetricTask] = useState<TTask | null>(
    null,
  );
  const currentQuest = useRef<TTask | null>(null);
  const [thirdPartyPoints, setThirdPartyPoints] = useState<number | undefined>(
    undefined,
  );

  const [responseLeaderboard, setResponseLeaderboard] = useState<any>({});

  /**
   * Gets active season from all the seasons
   * @param seasons {TSeason[]} - all seasons
   */
  const getActiveSeason = (seasons: TSeason[]): TSeason | undefined => {
    const now = moment().unix();
    const activeSeason = seasons.find((s) => {
      const preSeasonTime = moment(
        (s.startAt - s.extraTimeBeforeStart) * 1000,
      ).unix();
      const submittingStartTime = moment(
        (s.startAt + s.duration + s.rewardsCollectionBuffer) * 1000,
      ).unix();

      // season between before start time till submitting start time is active
      return now > preSeasonTime && now < submittingStartTime;
    });
    return activeSeason;
  };

  const getSeasonState = useCallback(() => {
    if (season) {
      const start = moment(season.startAt * 1000).unix();
      const end = moment((season.startAt + season.duration) * 1000).unix();
      // rewardsCollectionBuffer
      const preSeasonTime = moment(
        (season.startAt - season.extraTimeBeforeStart) * 1000,
      ).unix();
      const submittingStartTime = moment(
        (season.startAt + season.duration + season.rewardsCollectionBuffer) *
          1000,
      ).unix();
      const now = moment().unix();
      if (now >= preSeasonTime && now < start) {
        setSeasonState(SeasonStateEnum.PRE_SEASON);
      } else if (now >= start && now <= end) {
        setSeasonState(SeasonStateEnum.IN_SEASON);
      } else if (now >= end && now < submittingStartTime) {
        setSeasonState(SeasonStateEnum.BUFFER_TIME);
      } else if (now >= submittingStartTime) {
        // once season comes to this state, it won't be visible as its not
        // considered as active (kind of a waste state as of now)
        setSeasonState(SeasonStateEnum.SUBMITTING);
      }
    }
  }, [season]);

  // ranked user data fn call over here

  const findUserRank = (response: any, userId: string): number => {
    if (!userId) return -1;

    const foundIndex = response.rankedUserData.findIndex(
      (elm) => elm.userId === userId,
    );
    if (foundIndex !== -1) {
      return foundIndex + 1; // Return the index + 1 if the userId is found
    }
    return -1; // Return -10 if the userId is not found
  };

  const updateLeaderboardUsersAndUserRank = async () => {
    const response = await getLeaderboardUsersApi();
    setNextRefreshTime(response.uiData?.nextRefreshTime); // should be alright since it wont enter if condn after it has updated
    setResponseLeaderboard(response);
    if (user) {
      const userRankFetched = findUserRank(
        response,
        user?.appUser?.user.id || '',
      );
      setUserRank(userRankFetched);
    }
  };

  useEffect(() => {
    updateLeaderboardUsersAndUserRank();
  }, [showQPModal, pathname, user]);

  const getUserQuestsData = useCallback(async () => {
    if (user && userStakedNftIdData) {
      const data: TUserProgression = await getUsersQuestsProgression(
        user.appUser.user.id,
        userStakedNftIdData.length,
        userStakedNftIdData,
      );
      if (data && thirdPartyPoints) {
        data.totalCp += thirdPartyPoints;
        if (data.thirdPartyHeadStartPoints) {
          data.thirdPartyHeadStartPoints.totalPoints = thirdPartyPoints;
        } else {
          data.thirdPartyHeadStartPoints = {
            userId: '',
            totalPoints: thirdPartyPoints,
            rewardData: {},
          };
        }
      }
      setUserProgression(data);
    }
    return null;
  }, [user, userStakedNftIds, userStakedNftIdData, thirdPartyPoints]);

  const updateDailyLoginQuests = useCallback(async () => {
    if (user) {
      await updateDailyLoginQuest(user.appUser.user.id);
    }
    return null;
  }, [user]);

  const refreshQuests = async () => {
    const loader = utils.el('quests-loader');
    if (loader) {
      loader.classList.add('show');
    }
    setIsSeasonLoading(true);
    try {
      const data = await getQuests(userUtmSource);
      // TODO: loop through and get active season and not null
      // (which can only be in PRE_SEASON/IN_SEASON/SUBMITTING states)
      const seasonData: TSeason | undefined = getActiveSeason(
        data.data.seasonChallengeDTOList,
      );
      // const seasonData: TSeason | undefined = undefined;
      if (seasonData) {
        setSeason(seasonData);
      }
    } catch (e) {
      console.error('Error in refreshQuests\n', e);
    }
    setIsSeasonLoading(false);
    if (loader) {
      loader.classList.remove('show');
    }
  };

  const completedQuestsCount = useCallback(
    (dayIndex: number) => {
      const seasonal: number =
        userProgression?.seasonChallengeList.filter((q) => q.challengeComplete)
          .length || 0;
      const daily: number =
        userProgression?.dailyChallengeList[dayIndex]?.filter(
          (q) => q.challengeComplete,
        ).length || 0;
      return { daily, seasonal };
    },
    [userProgression],
  );

  useEffect(() => {
    console.log('refreshQuests');
    refreshQuests();
    // eslint-disable-next-line
  }, [userUtmSource]);

  useEffect(() => {
    try {
      const utmDetailsVal = JSON.parse(
        localStorage.getItem(biConstants.BI_LOCAL_STORAGE_KEYS.UTM_DETAILS) ||
          '{}',
      );
      if (utmDetailsVal && utmDetailsVal['utm_source']) {
        setUserUtmSource(utmDetailsVal['utm_source']);
      }
    } catch (e) {
      //DO NOTHING
    }
  }, []);

  useEffect(() => {
    getSeasonState();
    // eslint-disable-next-line
  }, [season]);

  useEffect(() => {
    getUserQuestsData();
    // eslint-disable-next-line
  }, [user, userStakedNftIdData]);

  useEffect(() => {
    updateDailyLoginQuests();
  }, [user]);

  // resetting season details and refecthing the quests on reaching submitting state
  useEffect(() => {
    if (seasonState === SeasonStateEnum.SUBMITTING) {
      setSeason(undefined);
      setSeasonState(undefined);
      refreshQuests();
    }
    // eslint-disable-next-line
  }, [seasonState]);

  const memoizedValue = useMemo(
    () => ({
      season,
      setSeason,
      seasonState,
      setSeasonState,
      isSeasonLoading,
      setIsSeasonLoading,
      getSeasonState,
      userProgression,
      setUserProgression,
      getUserQuestsData,
      notification,
      setNotification,
      completedQuestsCount,
      twitterMetricTask,
      setTwitterMetricTask,
      stakeModal,
      setStakeModal,
      showStakingRewards,
      setShowStakingRewards,
      currentQuest,
      blQrCode,
      rrQrCode,
      thirdPartyPoints,
      setThirdPartyPoints,
      userUtmSource,
      setUserUtmSource,
      profileImageModal,
      setProfileImageModal,
      setShowQPModal,
      showQPModal,
      responseLeaderboard,
      userRank,
      nextRefreshTime,
    }),
    [
      season,
      setSeason,
      seasonState,
      setSeasonState,
      isSeasonLoading,
      setIsSeasonLoading,
      getSeasonState,
      userProgression,
      setUserProgression,
      getUserQuestsData,
      notification,
      setNotification,
      completedQuestsCount,
      twitterMetricTask,
      setTwitterMetricTask,
      stakeModal,
      setStakeModal,
      showStakingRewards,
      setShowStakingRewards,
      currentQuest,
      blQrCode,
      rrQrCode,
      thirdPartyPoints,
      setThirdPartyPoints,
      userUtmSource,
      setUserUtmSource,
      profileImageModal,
      setProfileImageModal,
      setShowQPModal,
      showQPModal,
      responseLeaderboard,
      userRank,
      nextRefreshTime,
    ],
  );

  return (
    <SuperChampsContext.Provider value={memoizedValue}>
      {children}
    </SuperChampsContext.Provider>
  );
};

export const useSuperChampsContext = () => useContext(SuperChampsContext);
export default SuperChampsProvider;
export { SuperChampsContext };
