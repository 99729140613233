function typeCheckString(value?: string): asserts value is string {
  if (typeof value !== 'string') {
    throw new Error('Missing env variable');
  }
}

function typeCheckCurrentBlockchain(
  value?: string,
): asserts value is 'BASE' | 'BASE_SEPOLIA' {
  if (typeof value !== 'string' || !['BASE', 'BASE_SEPOLIA'].includes(value)) {
    throw new Error('Missing env variable, CURRENT_BLOCKCHAIN');
  }
}

function typeCheckJREnv(
  value?: string,
): asserts value is
  | 'development'
  | 'production'
  | 'stage'
  | 'testing'
  | 'automation'
  | 'qa'
  | 'moon'
  | 'mars'
  | 'jupiter'
  | 'prodtestnet' {
  if (
    typeof value !== 'string' ||
    ![
      'development',
      'production',
      'stage',
      'testing',
      'automation',
      'qa',
      'moon',
      'mars',
      'jupiter',
      'prodtestnet',
    ].includes(value)
  ) {
    throw new Error('Missing env variable, JR_ENV');
  }
}

typeCheckString(process.env.GATSBY_API_PREFIX);
typeCheckString(process.env.GATSBY_METAMASK_DEEPLINK_ANDROID);
typeCheckString(process.env.GATSBY_METAMASK_DEEPLINK_IOS);
typeCheckCurrentBlockchain(process.env.GATSBY_CURRENT_BLOCKCHAIN);
typeCheckJREnv(process.env.GATSBY_JR_ENV);

export const API_PREFIX = process.env.GATSBY_API_PREFIX;
export const METAMASK_DEEPLINK_ANDROID =
  process.env.GATSBY_METAMASK_DEEPLINK_ANDROID;
export const METAMASK_DEEPLINK_IOS = process.env.GATSBY_METAMASK_DEEPLINK_IOS;
export const CURRENT_BLOCKCHAIN = process.env.GATSBY_CURRENT_BLOCKCHAIN;
export const JR_ENV = process.env.GATSBY_JR_ENV;
