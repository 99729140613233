import utils from '../utils';

export const CURRENT_ENV_CURRENCY = utils.isProd() ? 'RLY' : 'USDC';

const ABI_BRIDGE = [
  {
    inputs: [
      { internalType: 'address', name: '_token', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
      { internalType: 'uint64', name: '_mintChainId', type: 'uint64' },
      { internalType: 'address', name: '_mintAccount', type: 'address' },
      { internalType: 'uint64', name: '_nonce', type: 'uint64' },
    ],
    name: 'deposit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

// It's actually token contract ABI, nothing to do with flow
const ABI_FLOW = [
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
];

export const SIGN_MESSAGE =
  'Signing lets us know that you are the confirmed owner of the wallet you are connecting. This is a secure action with no gas fees. It does not give Superchamps permission to execute transactions with your wallet.';
const testNetConfig = {
  CELER_BASE_URL: 'https://cbridge-v2-test.celer.network/',
  METAMASK_SIGN_MESSAGE: SIGN_MESSAGE + ' Nonce: {timestamp}',
  METAMASK_SIGN_MESSAGE_LINKING: SIGN_MESSAGE,
  CURRENCIES: {
    USDC: {
      walletCurrency: 'USDC',
      displayName: 'USDC',
      NETWORK_NAME: 'Goerli Test Network',
      TOKEN_CONTRACT_ADDRESS: '0xCbE56b00d173A26a5978cE90Db2E33622fD95A28', // token contract address
      BRIDGE_CONTRACT_ADDRESS: '0x28b37266a82aA7aae2b5ACA5a557C4Af0717b114', // bridge contract address
      CHAIN_ID_FLOW: 12340002,
      CHAIN_ID_ETH: 5,
      FLOW_DECIMALS: 8,
      ETH_DECIMALS: 6,
      MIN_WITHDRAWAL_AMT: 0.01,
      ABI_FLOW,
      ABI_BRIDGE,
    },
    RLY: {
      walletCurrency: 'RLY',
      displayName: 'RLY',
      NETWORK_NAME: '',
      TOKEN_CONTRACT_ADDRESS: '',
      BRIDGE_CONTRACT_ADDRESS: '',
      ABI_FLOW: [],
      ABI_BRIDGE: [],
      ETH_DECIMALS: 0,
      FLOW_DECIMALS: 0,
      CHAIN_ID_FLOW: 0,
      CHAIN_ID_ETH: 0,
    },
    SLAM: {
      walletCurrency: 'SLAM',
      displayName: 'SLAM',
      NETWORK_NAME: '',
      TOKEN_CONTRACT_ADDRESS: '',
      BRIDGE_CONTRACT_ADDRESS: '',
      ABI_FLOW: [],
      ABI_BRIDGE: [],
      ETH_DECIMALS: 0,
      FLOW_DECIMALS: 0,
      CHAIN_ID_FLOW: 0,
      CHAIN_ID_ETH: 0,
    },
    ETH: {
      walletCurrency: 'ETH',
      displayName: 'ETH',
      // ETH_DECIMALS: 8,
      // FLOW_DECIMALS: 6,
    },
    SOL: {
      walletCurrency: 'SOL',
      displayName: 'SOL',
      // ETH_DECIMALS: 8,
      // FLOW_DECIMALS: 6,
    },
  },
  BLOCKSCAN_URL: 'https://testnet.flowscan.org/transaction/',
  ETHER_BLOCKSCAN_URL: 'https://goerli.etherscan.io/tx/',
};

const mainNetConfig = {
  CELER_BASE_URL: 'https://cbridge-prod2.celer.app/',
  METAMASK_SIGN_MESSAGE: SIGN_MESSAGE + ' Nonce: {timestamp}',
  METAMASK_SIGN_MESSAGE_LINKING: SIGN_MESSAGE,
  CURRENCIES: {
    USDC: {
      walletCurrency: 'USDC',
      displayName: 'USDC',
      NETWORK_NAME: '',
      TOKEN_CONTRACT_ADDRESS: '',
      BRIDGE_CONTRACT_ADDRESS: '',
      CHAIN_ID_FLOW: -1, // destination chain
      CHAIN_ID_ETH: -1, // source chain
      FLOW_DECIMALS: 0,
      ETH_DECIMALS: 0,
      MIN_WITHDRAWAL_AMT: 1,
      // The Contract interface
      // https://etherscan.io/address/#code
      ABI_FLOW: [],
      // https://etherscan.io/address/#code
      ABI_BRIDGE: [],
    },
    RLY: {
      walletCurrency: 'RLY',
      displayName: 'RLY',
      NETWORK_NAME: 'Ethereum Mainnet',
      TOKEN_CONTRACT_ADDRESS: '0xf1f955016EcbCd7321c7266BccFB96c68ea5E49b',
      BRIDGE_CONTRACT_ADDRESS: '0xB37D31b2A74029B5951a2778F959282E2D518595',
      CHAIN_ID_FLOW: 12340001, // destination chain
      CHAIN_ID_ETH: 1, // source chain
      FLOW_DECIMALS: 8,
      ETH_DECIMALS: 18,
      MIN_WITHDRAWAL_AMT: 1500,
      // The Contract interface
      // https://etherscan.io/address/0xf1f955016EcbCd7321c7266BccFB96c68ea5E49b#code
      ABI_FLOW: ABI_FLOW,
      // https://etherscan.io/address/0xB37D31b2A74029B5951a2778F959282E2D518595#code
      ABI_BRIDGE,
    },
    ETH: {
      walletCurrency: 'ETH',
      displayName: 'ETH',
      // ETH_DECIMALS: 8,
      // FLOW_DECIMALS: 6,
    },
    SOL: {
      walletCurrency: 'SOL',
      displayName: 'SOL',
      // ETH_DECIMALS: 8,
      // FLOW_DECIMALS: 6,
    },
  },
  BLOCKSCAN_URL: 'https://flowscan.org/transaction/',
  ETHER_BLOCKSCAN_URL: 'https://etherscan.io/tx/',
};

export const getEnvSpecificWeb3Config = ():
  | typeof testNetConfig
  | typeof mainNetConfig => {
  if (utils.isProd()) {
    return mainNetConfig;
  }
  return testNetConfig;
};
