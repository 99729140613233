// eslint-disable-next-line import/no-unresolved
import { globalHistory } from '@reach/router';
import { useSyncExternalStore } from 'react';

function subscribe(cb: () => void) {
  const unsub = globalHistory.listen(cb);

  return unsub;
}

function useLocation() {
  const snapshot = useSyncExternalStore(
    subscribe,
    () => globalHistory.location,
    () => ({
      pathname: 'login',
    }),
  );

  return snapshot;
}

export default useLocation;
