import Cookies from 'js-cookie';

import { getCurrentTabForBi, getSeasonStartTime } from '@/common/utils';

import { sendBIEvent } from '../bi-api';
import {
  biFields,
  EChallengType,
  ECtaNames,
  EQuestTriggerTab,
  EWebsiteTabNames,
} from '../bi-constants';
import { TBiEvent, TBiEventBeforeLogin } from '../bi-types';
import { biUtils } from '../bi-utils';

const saveCurrentSessionIndexAndStartTime = (
  sessionIndex,
  sessionStartTime,
) => {
  const userDefaults = window.localStorage;
  userDefaults.setItem('BLITZ_LAST_SESSION_INDEX', sessionIndex.toString());
  userDefaults.setItem(
    'BLITZ_LAST_SESSION_START_DATE',
    sessionStartTime.toString(),
  );
};

const isSameDay = (date1, date2) => {
  return date1.toDateString() === date2.toDateString();
};

const getCurrentSessionIndex = (sessionStartTime) => {
  const userDefaults = window.localStorage;
  const lastSessionIndex = parseInt(
    userDefaults.getItem('BLITZ_LAST_SESSION_INDEX') || '0',
  );
  const lastSessionStartTime = parseInt(
    userDefaults.getItem('BLITZ_LAST_SESSION_START_DATE') || '0',
  );

  if (!lastSessionStartTime) return 1;

  const currentSessionStartDate = new Date(sessionStartTime * 1000);
  const lastSessionStartDate = new Date(lastSessionStartTime * 1000);

  const isSameDayTrue = isSameDay(
    currentSessionStartDate,
    lastSessionStartDate,
  );
  return isSameDayTrue ? lastSessionIndex + 1 : 1;
};

export const defaultBiEvents = () => {
  let defaultBi = biUtils.getDefaultBiEventPostLogin();
  if (!defaultBi) {
    defaultBi = biUtils.getDefaultBiEventBeforeLogin();
  }
  return defaultBi;
};
export const commonBiEvents = {
  /** row 6 */
  userClickBuyNftTabOrAnyOtherTab: (
    strField1Value: string,
    tabName: EWebsiteTabNames,
  ) => {
    const biEvent: TBiEvent = {
      ...biUtils.getDefaultBiEventPostLogin(),
      event_name: biFields.eventNames.WEB_ONBOARDING,
      str_field0: biFields.strField0Types.CLICK,
      str_field1: strField1Value,
      str_field2: tabName,
      str_field3: biFields.strField3Types.WEB,
      str_field4: biUtils.getIsUserNewFromLocalStorage()
        ? biFields.strField4Types.NEW
        : biFields.strField4Types.RETURNING,
    };

    sendBIEvent(biEvent, 'user Clicks on Buy NFT tab or any other tab, row 6');
  },

  /** row 33 */
  userClicksOnSomeCta: (
    tabName: EWebsiteTabNames,
    ctaName: ECtaNames,
    primaryAbstraction?: string,
    cta_redirection?: string,
    secondaryAbstractions?: string,
    nftIds?: string,
    primaryMintPassIds?: string,
    primaryNftCount?: number,
    primaryMintPassCount?: number,
    moreOptions?: any,
  ) => {
    const defaultBiEvent = biUtils.getDefaultBiEventPostLogin();
    let biEvent: TBiEvent | TBiEventBeforeLogin;
    if (defaultBiEvent) {
      biEvent = {
        ...defaultBiEvent,
        event_name: biFields.eventNames.WEB_VIEW,
        str_field0: biFields.strField0Types.CLICK,
        str_field1: ctaName,
        str_field2: tabName,
        str_field3: biFields.strField3Types.WEB,
        str_field4: biUtils.getIsUserNewFromLocalStorage()
          ? biFields.strField4Types.NEW
          : biFields.strField4Types.RETURNING,
        str_field5: primaryAbstraction,
        str_field6: cta_redirection,
        str_field7: secondaryAbstractions,
        str_field8: nftIds,
        str_field9: primaryMintPassIds,
        int_field1: primaryNftCount,
        int_field2: primaryMintPassCount,
        ...moreOptions,
      };
    } else {
      biEvent = {
        ...biUtils.getDefaultBiEventBeforeLogin(),
        event_name: biFields.eventNames.WEB_VIEW,
        str_field0: biFields.strField0Types.CLICK,
        str_field1: ctaName,
        str_field2: tabName,
        str_field3: biFields.strField3Types.WEB,
        str_field4: !biUtils.getDeviceDetailsFromLocalStorage()
          ? biFields.strField4Types.NEW
          : biFields.strField4Types.RETURNING,
        str_field5: primaryAbstraction || '',
        str_field6: cta_redirection || '',
        str_field7: secondaryAbstractions?.toString() || '',
        str_field8: nftIds?.toString() || '',
        str_field9: primaryMintPassIds?.toString() || '',
        int_field1: primaryNftCount || 0,
        int_field2: primaryMintPassCount || 0,
        ...moreOptions,
      };
    }

    sendBIEvent(biEvent, 'user clicks on some cta on the website, row 33');
  },
  questCta: (
    challengeType: EChallengType,
    ctaText: string,
    tab: EQuestTriggerTab,
    params: {
      seasonId: number;
      challengeId: number;
      description: string;
      challengeTask: string;
      isHero: boolean;
      currentQuestPoints: number;
      currentSeasonPoints: number;
      currentQuestLevel: number;
      maxQuestLevel: number;
      totalSeasonalChallenges: number;
      totalDailyChallenges: number;
      completedSeasonalChallenges: number;
      completedDailyChallenges: number;
      seasonStartTime: number;
      seasonEndTime: number;
      rewardValue: number;
      title: string;
      section: string;
    },
  ) => {
    const defaultBiEvent = defaultBiEvents();
    const biEvent: TBiEvent | TBiEventBeforeLogin = {
      ...defaultBiEvent,
      event_name: biFields.eventNames.META_GAME,
      str_field0: biFields.strField0Types.CLICK,
      str_field1: ECtaNames.QUEST_CTA,
      str_field2: challengeType,
      str_field3: ctaText,
      str_field4: tab,
      str_field5: params.section,
      str_field6: params.title,
      str_field7: params.description,
      str_field8: params.challengeId,
      str_field9: params.challengeTask,
      str_field10: 'CP',
      str_field11: 'QuestPoints',
      str_field13: params.seasonId,
      str_field16: params.isHero,
      int_field0: params.currentQuestPoints,
      int_field1: params.currentSeasonPoints,
      int_field2: params.currentQuestLevel,
      int_field3: params.maxQuestLevel,
      int_field4: params.totalSeasonalChallenges,
      int_field5: params.totalDailyChallenges,
      int_field6: params.completedSeasonalChallenges,
      int_field7: params.completedDailyChallenges,
      int_field15: params.seasonStartTime,
      int_field16: params.seasonEndTime,
      float_field0: params.rewardValue,
    };

    sendBIEvent(biEvent, 'user clicks on some cta on the website, row 33');
  },
  view: (options: any) => {
    const defaultBiEvent = defaultBiEvents();
    const biEvent: TBiEvent | TBiEventBeforeLogin = {
      ...defaultBiEvent,
      event_name: options.event_name || biFields.eventNames.WEB_VIEW,
      str_field0: biFields.strField0Types.VIEW_OPEN,
      int_field0: getSeasonStartTime(),
      ...options,
    };

    sendBIEvent(biEvent, 'Common - view_open');
  },
  sessionStart: (options: any) => {
    let sessionCount = 0;
    if (typeof window !== 'undefined') {
      const currentDate = new Date();
      const currentEpoch = Math.floor(currentDate.getTime() / 1000);
      localStorage.setItem('blitzsessionId', currentEpoch);
      sessionCount = getCurrentSessionIndex(currentEpoch);
      saveCurrentSessionIndexAndStartTime(sessionCount, currentEpoch);
    }
    const defaultBiEvent = defaultBiEvents();
    const biEvent: TBiEvent | TBiEventBeforeLogin = {
      ...defaultBiEvent,
      event_name: options.event_name || biFields.eventNames.SESSION_START,
      int_field0: sessionCount,
      ...options,
    };
    sendBIEvent(biEvent, 'Common - view_open');
  },
  sessionLength: (options: any) => {
    const defaultBiEvent = defaultBiEvents();
    //     long long length = [self getCurrentEpochTime] - self->sessionStartTimeStamp;
    //     NSString *sessionLengthStr = [NSString stringWithFormat:@"%lld", length];
    let sessionLength = 0;
    if (typeof window !== 'undefined') {
      const currentDate = new Date();
      const currentEpoch = Math.floor(currentDate.getTime() / 1000);
      const sessionStartTime = parseInt(localStorage.getItem('blitzsessionId'));
      sessionLength = currentEpoch - sessionStartTime;
    }
    const biEvent: TBiEvent | TBiEventBeforeLogin = {
      ...defaultBiEvent,
      event_name: options.event_name || biFields.eventNames.SESSION_LENGTH,
      session_length: sessionLength,
      ...options,
    };
    sendBIEvent(biEvent, 'Common - view_open');
  },
  click: (options: any) => {
    const defaultBiEvent = defaultBiEvents();
    const biEvent: TBiEvent | TBiEventBeforeLogin = {
      ...defaultBiEvent,
      event_name: options.event_name || biFields.eventNames.WEB_VIEW,
      str_field0: biFields.strField0Types.CLICK,
      int_field0: getSeasonStartTime(),
      ...options,
    };

    sendBIEvent(biEvent, 'Common - click');
  },
  fbConversionPixel: (options: any) => {
    const fbp = Cookies.get('_fbp') || '';
    const fbc = Cookies.get('_fbc') || '';
    const defaultBiEvent = defaultBiEvents();
    const biEvent: TBiEvent | TBiEventBeforeLogin = {
      ...defaultBiEvent,
      event_name: biFields.eventNames.FB_CONVERSION_PIXEL,
      str_field0: fbc,
      str_field1: fbp,
      ...options,
    };

    sendBIEvent(biEvent, 'Fb Conversion Pixel');
  },
  stateChange: (options: any) => {
    const defaultBiEvent = defaultBiEvents();
    const biEvent: TBiEvent | TBiEventBeforeLogin = {
      ...defaultBiEvent,
      event_name: options.event_name || biFields.eventNames.WEB_VIEW,
      str_field0: biFields.strField0Types.STATE_CHANGE,
      int_field0: getSeasonStartTime(),
      ...options,
    };

    sendBIEvent(biEvent, 'Common - state_change');
  },
  ctaClick: (text: string, action: string, userRank?: number) => {
    const defaultBiEvent = defaultBiEvents();
    const biEvent: TBiEvent | TBiEventBeforeLogin = {
      ...defaultBiEvent,
      event_name: biFields.eventNames.WEB_VIEW,
      str_field0: biFields.strField0Types.CLICK,
      str_field1: 'cta',
      str_field2: getCurrentTabForBi(),
      str_field4: text,
      str_field5: action,
      int_field0: getSeasonStartTime(),
      ...(userRank !== undefined && { int_field10: userRank }),
    };

    sendBIEvent(biEvent, 'Common - click');
  },
};
